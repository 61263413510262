import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaEllipsisV, FaFolderPlus, FaClipboardList, FaBookOpen, FaFolderOpen, FaRegListAlt, FaBook, FaTasks } from "react-icons/fa";
import CountryFlag from "react-country-flag";
import darkLogo from "../web logo.png";
import lightLogo from "../web logo light.png";
import psicon from "../playstore icon.png";
import { IoHomeOutline } from "react-icons/io5";

const Navbar = ({ onLanguageChange, onThemeChange, isDarkTheme }) => {
  const [language, setLanguage] = useState("tr");
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const themes = {
    light: {
      nav: "bg-gray-400",
      text: "text-[#111827] select-none",
      hover: "hover:text-black",
      button: "bg-[#F9FAFB]",
      dropdown: "bg-[#F9FAFB]",
      dropdownHover: "hover:bg-[#6B7280] hover:text-white",
      shadow: "shadow-md",
      logo: lightLogo,
      homeIcon: "text-gray-800",
      homeIconHover: "hover:text-gray-600",
    },
    dark: {
      nav: "bg-gray-900",
      text: "text-white select-none",
      hover: "hover:text-yellow-400",
      button: "bg-gray-800",
      dropdown: "bg-gray-800",
      dropdownHover: "hover:bg-gray-700",
      shadow: "shadow-lg",
      logo: darkLogo,
      homeIcon: "text-yellow-400",
      homeIconHover: "hover:text-yellow-300",
    },
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "tr";
    setLanguage(savedLanguage);
    onLanguageChange(savedLanguage);
  }, [onLanguageChange]);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    onLanguageChange(newLanguage);
    setIsOpen(false);
  };

  const toggleTheme = () => {
    const newTheme = !isDarkTheme;
    localStorage.setItem("theme", newTheme ? "dark" : "light");
    onThemeChange(newTheme);
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  return (
    <nav
      className={`${currentTheme.nav} py-3 px-4 md:px-6 transition-colors duration-300 sticky top-0 z-50 ${currentTheme.shadow}`}
    >
      <div className="container mx-auto flex justify-between items-center">
        <NavLink to="/" className="flex items-center group">
          <IoHomeOutline
            className={`h-7 w-7 md:h-8 md:w-8 transition-all duration-300 ease-in-out transform 
            ${currentTheme.homeIcon} ${currentTheme.homeIconHover}
            group-hover:scale-110 group-hover:rotate-6`}
            aria-label="Ana Sayfa"
          />
        </NavLink>

        <button
          className={`md:hidden ${currentTheme.text} text-xl focus:outline-none p-2 rounded-full transition-colors duration-300`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Menü"
        >
          <FaEllipsisV />
        </button>

        <div
          className={`${
            isMenuOpen
              ? `absolute top-16 right-4 ${currentTheme.dropdown} rounded-lg ${currentTheme.shadow} w-64 z-10 py-2 overflow-hidden`
              : "hidden"
          } md:flex md:static md:bg-transparent md:shadow-none md:w-auto md:py-0 md:space-x-6 md:items-center`}
        >
          <NavLink
            to="/collection"
            className={`block ${currentTheme.text} ${currentTheme.hover} text-lg md:text-xl py-2 px-4 rounded-md font-mono md:inline-block transition-all duration-300 hover:underline hover:scale-105`}
          >
            <FaFolderOpen className={`inline-block mr-2 ${currentTheme.text}`} />
            {language === "en" ? "Create Collection" : "Koleksiyon Oluştur"}
          </NavLink>
          <NavLink
            to="/words"
            className={`block ${currentTheme.text} ${currentTheme.hover} text-lg md:text-xl py-2 px-4 rounded-md font-mono md:inline-block transition-all duration-300 hover:underline hover:scale-105`}
          >
            <FaTasks className={`inline-block mr-2 ${currentTheme.text}`} />
            {language === "en" ? "Create Word Group" : "Quiz Oluştur"}
          </NavLink>
          
          <NavLink
            to="/guide"
            className={`block ${currentTheme.text} ${currentTheme.hover} text-lg md:text-xl py-2 px-4 rounded-md font-mono md:inline-block transition-all duration-300 hover:underline hover:scale-105`}
          >
            <FaBook className={`inline-block mr-2 ${currentTheme.text}`} />
            {language === "en" ? "User Guide" : "Kullanım Kılavuzu"}
          </NavLink>

          <div className="relative mx-4 my-2 md:my-0">
            <button
              onClick={toggleTheme}
              className={`
                w-16 md:w-20 h-8 md:h-10 rounded-full relative focus:outline-none
                transition-all duration-500 ease-in-out
                ${isDarkTheme ? "bg-gray-700" : "bg-gray-200"}
              `}
              aria-label="Tema değiştir"
            >
              <div
                className={`
                absolute inset-0 rounded-full
                transition-opacity duration-500
                ${isDarkTheme ? "opacity-100" : "opacity-0"}
                bg-gradient-to-r from-gray-700 to-gray-800
              `}
              />

              <div
                className={`
                  absolute top-1 w-6 md:w-8 h-6 md:h-8 rounded-full
                  transform transition-all duration-500 ease-in-out
                  ${
                    isDarkTheme
                      ? "translate-x-9 md:translate-x-10 bg-gray-600"
                      : "translate-x-1 bg-yellow-500"
                  }
                  shadow-lg flex items-center justify-center
                `}
              >
                <div
                  className={`absolute transition-opacity duration-500 ${
                    isDarkTheme ? "opacity-0" : "opacity-100"
                  }`}
                >
                  <svg
                    className="w-3 h-3 md:w-4 md:h-4 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  className={`absolute transition-opacity duration-500 ${
                    isDarkTheme ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <svg
                    className="w-3 h-3 md:w-4 md:h-4 text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                </div>
              </div>

              <div className="absolute inset-0 flex items-center justify-between px-2">
                <span
                  className={`text-yellow-400 transition-opacity duration-500 ${
                    isDarkTheme ? "opacity-30" : "opacity-0"
                  }`}
                >
                  <svg
                    className="w-3 h-3 md:w-4 md:h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  className={`text-gray-500 transition-opacity duration-500 ${
                    isDarkTheme ? "opacity-0" : "opacity-30"
                  }`}
                >
                  <svg
                    className="w-3 h-3 md:w-4 md:h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                </span>
              </div>
            </button>
          </div>

          <div className="relative block md:inline-block">
            {/* <button
              onClick={() => setIsOpen(!isOpen)}
              className={`flex items-center ${currentTheme.text} ${currentTheme.button} py-2 px-4 rounded focus:outline-none transition-colors duration-300`}
            >
              <CountryFlag
                countryCode={language === "en" ? "US" : "TR"}
                svg
                style={{ width: "1.5em", height: "1.5em" }}
                className="mr-2"
              />
              {language === "en" ? "English" : "Türkçe"}
            </button> */}
            {isOpen && (
              <div
                className={`absolute right-0 mt-2 w-64 ${currentTheme.dropdown} rounded-lg ${currentTheme.shadow} overflow-hidden`}
              >
                <div
                  onClick={() => handleLanguageChange("tr")}
                  className={`flex items-center px-4 py-2 ${currentTheme.text} ${currentTheme.dropdownHover} cursor-pointer transition-colors duration-300`}
                >
                  <CountryFlag
                    countryCode="TR"
                    svg
                    style={{ width: "1.5em", height: "1.5em" }}
                    className="mr-2"
                  />
                  Türkçe
                </div>
              </div>
            )}
          </div>

          <div className="my-3 md:my-0 block md:inline-block text-center select-none">
            <a
              href="https://play.google.com/store/apps/details?id=com.forever587.Words"
              target="_blank"
              rel="noopener noreferrer"
              className="transition-transform duration-300 hover:scale-110 inline-block"
            >
              <img
                src={psicon}
                className="h-6 w-6 md:h-7 md:w-7 mx-auto"
                alt="Play Store"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
