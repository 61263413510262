import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Collection from "./components/Collection";
import Home from "./components/Home";
import WordsGroup from "./components/WordsGroup";
import CollectionDetail from "./components/CollectionDetail";
import Quiz from "./components/Quiz";
import EditWordGroup from "./components/EditWordGroup ";
import { useEffect, useState } from "react";
import MixedQuiz from "./components/MixedQuiz";
import Guide from "./components/Guide";

function App() {
  const [language, setLanguage] = useState("en");
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    setLanguage(savedLanguage);
    
    // Tema tercihi için localStorage kontrolü
    const savedTheme = localStorage.getItem("theme");
    setIsDarkTheme(savedTheme === "dark");
  }, []);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  // Tema değişikliği için handler fonksiyonu
  const handleThemeChange = (newTheme) => {
    setIsDarkTheme(newTheme);
    localStorage.setItem("theme", newTheme ? "dark" : "light");
  };

  return (
    <div className="flex flex-col h-screen">
      <Navbar 
        onLanguageChange={handleLanguageChange} 
        onThemeChange={handleThemeChange}
        isDarkTheme={isDarkTheme}
      />
      <main className="flex-1 overflow-auto flex flex-col h-full">
        <Routes>
          <Route
            path="/"
            element={<Home language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route
            path="/collection"
            element={<Collection language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route path="/words" element={<WordsGroup language={language} isDarkTheme={isDarkTheme} />} />
          <Route
            path="/collection/:id"
            element={<CollectionDetail language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route
            path="/quiz/:collectionId/:groupId"
            element={<Quiz language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route
            path="/edit-word-group/:collectionId/:groupId"
            element={<EditWordGroup language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route
            path="/mixed-quiz/:id"
            element={<MixedQuiz language={language} isDarkTheme={isDarkTheme} />}
          />
          <Route
            path="/guide"
            element={<Guide language={language} isDarkTheme={isDarkTheme} />}
          />
        </Routes>
      </main>
    </div>
  );
}

export default App;