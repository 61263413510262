import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaFolder, FaSort, FaThLarge, FaList, FaPalette, FaEdit, FaDownload, FaUpload } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = ({ language, isDarkTheme }) => {
  const [collections, setCollections] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("name-asc");
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [viewMode, setViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem('preferredViewMode');
    return savedViewMode || 'grid';
  });
  // Düzenleme için eklenen state'ler
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [collectionToRename, setCollectionToRename] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState("");
  // Yükleme işlemi için eklenen state
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  
  const collectionsPerPage = 9;

  const themes = {
    light: {
      background: "bg-gray-200",
      componentBg: "bg-gray-300 shadow-md",
      text: "text-neutral-900 select-none",
      // hover: "hover:text-neutral-600",
      button: "bg-neutral-300 hover:bg-neutral-400",
      dropdown: "bg-neutral-50",
      dropdownHover: "hover:bg-neutral-200",
      border: "border-neutral-300",
      modal: "bg-neutral-50",
      modalOverlay: "bg-neutral-500 bg-opacity-75",
      activeViewButton: "bg-yellow-400 text-gray-900",
      pagination: {
        wrapper: "bg-gray-300 border-neutral-300",
        button: {
          normal: "bg-white text-neutral-900 border border-neutral-300 hover:bg-neutral-100",
          active: "bg-blue-500 text-white border border-blue-600",
          disabled: "bg-gray-200 text-gray-400 border border-gray-300 cursor-not-allowed"
        },
        navigation: {
          active: "bg-blue-500 hover:bg-blue-600 text-white",
          disabled: "bg-gray-200 text-gray-400 border border-gray-300"
        }
      }
    },
    dark: {
      background: "bg-gray-800",
      componentBg: "bg-gray-900 shadow-md",
      text: "text-white select-none",
      // hover: "hover:text-yellow-400",
      button: "bg-gray-700 hover:bg-gray-600",
      dropdown: "bg-gray-700",
      dropdownHover: "hover:bg-gray-600",
      border: "border-gray-700",
      modal: "bg-gray-800",
      modalOverlay: "bg-gray-900 bg-opacity-75",
      activeViewButton: "bg-yellow-400 text-gray-900",
      pagination: {
        wrapper: "bg-gray-800 border-gray-700",
        button: {
          normal: "bg-gray-700 text-white border border-gray-600 hover:bg-gray-600",
          active: "bg-yellow-500 text-gray-900 border border-yellow-600",
          disabled: "bg-gray-900 text-gray-600 border border-gray-800 cursor-not-allowed"
        },
        navigation: {
          active: "bg-blue-500 hover:bg-blue-600 text-white",
          disabled: "bg-gray-900 text-gray-600 border border-gray-800"
        }
      }
    }
  };

  const colorOptions = {
    light: [
      { name: 'Default', value: 'text-neutral-900' },
      { name: 'Red', value: 'text-red-600' },
      { name: 'Blue', value: 'text-blue-600' },
      { name: 'Green', value: 'text-green-600' },
      { name: 'Purple', value: 'text-purple-600' },
      { name: 'Yellow', value: 'text-yellow-600' },
      { name: 'Pink', value: 'text-pink-600' },
      { name: 'Orange', value: 'text-orange-600' },
    ],
    dark: [
      { name: 'Default', value: 'text-white' },
      { name: 'Red', value: 'text-red-400' },
      { name: 'Blue', value: 'text-blue-400' },
      { name: 'Green', value: 'text-green-400' },
      { name: 'Purple', value: 'text-purple-400' },
      { name: 'Yellow', value: 'text-yellow-400' },
      { name: 'Pink', value: 'text-pink-400' },
      { name: 'Orange', value: 'text-orange-400' },
    ]
  };

  // Renk seçiciyi aç
  const handleColorPickerOpen = (collectionId) => {
    setSelectedCollectionId(collectionId);
    setShowColorPicker(true);
  };

  // Renk seç
  const handleColorSelect = (color) => {
    const updatedCollections = collections.map(collection => {
      if (collection.id === selectedCollectionId) {
        return {
          ...collection,
          colors: {
            ...(collection.colors || {}),
            [isDarkTheme ? 'dark' : 'light']: color
          }
        };
      }
      return collection;
    });

    setCollections(updatedCollections);
    localStorage.setItem("collections", JSON.stringify(updatedCollections));
    setShowColorPicker(false);
    
    toast.success(
      language === "en"
        ? "Color updated successfully."
        : "Renk başarıyla güncellendi."
    );
  };

  // Koleksiyon rengini al
  const getCollectionColor = (collection) => {
    const themeMode = isDarkTheme ? 'dark' : 'light';
    return collection.colors?.[themeMode] || (isDarkTheme ? 'text-white' : 'text-neutral-900');
  };

  const getCollectionStats = (collectionId) => {
    const allCollections = JSON.parse(localStorage.getItem("collections")) || [];
    const collection = allCollections.find(c => c.id === collectionId);
    
    if (!collection || !collection.wordGroups) {
      return { groupCount: 0, wordCount: 0 };
    }
    
    const groupCount = collection.wordGroups.length;
    const wordCount = collection.wordGroups.reduce((total, group) => {
      return total + (group.words ? group.words.length : 0);
    }, 0);
    
    return { groupCount, wordCount };
  };

  // Yeniden adlandırma modalını aç
  const handleRenameModalOpen = (collection) => {
    setCollectionToRename(collection);
    setNewCollectionName(collection.name);
    setShowRenameModal(true);
  };

  // İsmi değiştir
const handleRename = () => {
  if (!newCollectionName.trim()) {
    toast.error(
      language === "en"
        ? "Collection name cannot be empty."
        : "Koleksiyon adı boş olamaz."
    );
    return;
  }

  // Aynı isimde başka bir koleksiyon var mı kontrol et
  const isDuplicate = collections.some(
    collection => collection.id !== collectionToRename.id && 
                  collection.name.toLowerCase() === newCollectionName.trim().toLowerCase()
  );

  if (isDuplicate) {
    toast.error(
      language === "en"
        ? "A collection with this name already exists. Please use a different name."
        : "Bu isimde bir koleksiyon zaten mevcut. Lütfen farklı bir isim kullanın."
    );
    return;
  }

  const updatedCollections = collections.map(collection => {
    if (collection.id === collectionToRename.id) {
      return {
        ...collection,
        name: newCollectionName.trim()
      };
    }
    return collection;
  });

  setCollections(updatedCollections);
  localStorage.setItem("collections", JSON.stringify(updatedCollections));
  setShowRenameModal(false);
  
  toast.success(
    language === "en"
      ? "Collection renamed successfully."
      : "Koleksiyon adı başarıyla değiştirildi."
  );
};

  const ColorPicker = ({ onClose }) => (
    <div className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}>
      <div className={`${currentTheme.modal} p-4 rounded-md shadow-md`}>
        <h3 className="mb-4 font-bold">
          {language === "en" ? "Select Color" : "Renk Seç"}
        </h3>
        <div className="grid grid-cols-4 gap-2">
          {colorOptions[isDarkTheme ? 'dark' : 'light'].map((color) => (
            <button
              key={color.name}
              onClick={() => handleColorSelect(color.value)}
              className={`p-4 rounded ${color.value} hover:opacity-80 transition-opacity`}
            >
              <FaFolder className="text-2xl" />
            </button>
          ))}
        </div>
        <button
          onClick={onClose}
          className={`${currentTheme.button} mt-4 py-1 px-3 rounded w-full`}
        >
          {language === "en" ? "Cancel" : "İptal"}
        </button>
      </div>
    </div>
  );

  // İsim değiştirme modalı
  const RenameModal = () => (
    <div className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}>
      <div className={`${currentTheme.modal} p-4 rounded-md shadow-md max-w-md w-full`}>
        <h3 className="mb-4 font-bold">
          {language === "en" ? "Rename Collection" : "Koleksiyon Adını Değiştir"}
        </h3>
        <input
          type="text"
          value={newCollectionName}
          onChange={(e) => setNewCollectionName(e.target.value)}
          className="w-full p-2 mb-4 border rounded text-black"
          autoFocus
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRename();
            }
          }}
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setShowRenameModal(false)}
            className={`${currentTheme.button} py-1 px-3 rounded`}
          >
            {language === "en" ? "Cancel" : "İptal"}
          </button>
          <button
            onClick={handleRename}
            className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700"
          >
            {language === "en" ? "Save" : "Kaydet"}
          </button>
        </div>
      </div>
    </div>
  );

  // Create the collection data
  const englishWordsCollection = {
    id: Math.floor(Math.random() * 1000000000000),
    name: "İngilizce Kelimeler",
    createdAt: new Date().toISOString(),
    wordGroups: [
      {
        id: Math.floor(Math.random() * 1000000000000),
        name: "Renkler",
        createdAt: new Date().toISOString(),
        words: [
          { question: "red", answer: "kırmızı" },
          { question: "blue", answer: "mavi" },
          { question: "green", answer: "yeşil" },
          { question: "yellow", answer: "sarı" },
          { question: "orange", answer: "turuncu" },
          { question: "purple", answer: "mor" },
          { question: "pink", answer: "pembe" },
          { question: "brown", answer: "kahverengi" },
          { question: "white", answer: "beyaz" },
          { question: "black", answer: "siyah" },
          { question: "gray", answer: "gri" },
          { question: "navy blue", answer: "lacivert" },
          { question: "light blue", answer: "açık mavi" },
          { question: "dark blue", answer: "koyu mavi" },
          { question: "light green", answer: "açık yeşil" },
          { question: "dark green", answer: "koyu yeşil" },
          { question: "silver", answer: "gümüş" },
          { question: "gold", answer: "altın" },
          { question: "beige", answer: "bej" },
          { question: "burgundy", answer: "bordo" },
          { question: "violet", answer: "eflatun" },
          { question: "emerald green", answer: "zümrüt yeşili" },
          { question: "turquoise", answer: "camgöbeği" },
          { question: "charcoal", answer: "füme" },
          { question: "dark red", answer: "koyu kırmızı" }
        ]
      }
    ]
  };

  // Get existing collections
  const existingCollections = JSON.parse(localStorage.getItem("collections")) || [];

  // Check if collection with same name already exists
  const collectionExists = existingCollections.some(
    collection => collection.name === "İngilizce Kelimeler"
  );

  // Only add if collection doesn't exist
  if (!collectionExists) {
    existingCollections.push(englishWordsCollection);
    localStorage.setItem("collections", JSON.stringify(existingCollections));
    console.log("Collection added successfully!");
  } else {
    console.log("Collection 'İngilizce Kelimeler' already exists!");
  }

  // Koleksiyon indirme fonksiyonu
  const handleDownloadCollection = (collection) => {
    // Koleksiyonun bir kopyasını oluştur ve gereksiz alanları temizle
    const collectionToDownload = JSON.parse(JSON.stringify(collection));
    
    // Rename wordGroups to quizGroups
    if (collectionToDownload.wordGroups) {
      collectionToDownload.quizGroups = collectionToDownload.wordGroups.map(group => {
        // Convert words array with question/answer format
        const questions = (group.words || []).map(word => {
          return {
            question: word.question || word.turkish || "",
            answer: word.answer || word.english || ""
          };
        });
        
        return {
          id: group.id,
          name: group.name,
          createdAt: group.createdAt,
          questions: questions
        };
      });
      
      // Remove the original wordGroups
      delete collectionToDownload.wordGroups;
    }
    
    // JSON dosyasını oluştur
    const dataStr = JSON.stringify(collectionToDownload, null, 2);
    const dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
    
    // Dosya indirme işlemi
    const exportFileDefaultName = `${collection.name.replace(/\s+/g, '_')}_collection.json`;
    
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
    
    toast.success(
      language === "en"
        ? "Collection downloaded successfully."
        : "Koleksiyon başarıyla indirildi."
    );
  };
  
  // Koleksiyon yükleme fonksiyonu
  const handleCollectionUpload = (event) => {
    const file = event.target.files[0];
    setFileUploadError("");
    
    if (!file) return;
    
    // Sadece JSON dosyalarını kabul et
    if (file.type !== "application/json" && !file.name.endsWith('.json')) {
      setFileUploadError(
        language === "en"
          ? "Please select a JSON file."
          : "Lütfen JSON dosyası seçin."
      );
      return;
    }
    
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const uploadedCollection = JSON.parse(e.target.result);
        
        // Handle new collection format with quizGroups
        if (uploadedCollection.quizGroups && !uploadedCollection.wordGroups) {
          uploadedCollection.wordGroups = uploadedCollection.quizGroups.map(group => {
            const words = (group.questions || []).map(item => {
              return {
                id: Math.floor(Math.random() * 1000000000000),
                question: item.question || "",
                answer: item.answer || ""
              };
            });
            
            return {
              id: group.id || Math.floor(Math.random() * 1000000000000),
              name: group.name,
              createdAt: group.createdAt || new Date().toISOString(),
              words: words
            };
          });
          
          // Remove quizGroups after conversion
          delete uploadedCollection.quizGroups;
        }
        
        // Geçerli bir koleksiyon formatı kontrolü
        if (!uploadedCollection.name || 
            (!Array.isArray(uploadedCollection.wordGroups) && !Array.isArray(uploadedCollection.quizGroups))) {
          throw new Error("Invalid collection format");
        }
        
        // Mevcut koleksiyonları getir
        const existingCollections = JSON.parse(localStorage.getItem("collections")) || [];
        
        // Aynı isimde koleksiyon var mı kontrol et
        let newCollectionName = uploadedCollection.name;
        let counter = 1;
        
        while (existingCollections.some(c => c.name === newCollectionName)) {
          newCollectionName = `${uploadedCollection.name} (${counter})`;
          counter++;
        }
        
        // Yeni koleksiyon adını ayarla
        uploadedCollection.name = newCollectionName;
        
        // Yeni ID ata
        uploadedCollection.id = Math.floor(Math.random() * 1000000000000);
        
        // Oluşturulma tarihi ekle
        if (!uploadedCollection.createdAt) {
          uploadedCollection.createdAt = new Date().toISOString();
        }
        
        // Her kelime grubu için yeni ID ata
        if (uploadedCollection.wordGroups) {
          uploadedCollection.wordGroups = uploadedCollection.wordGroups.map(group => ({
            ...group,
            id: Math.floor(Math.random() * 1000000000000),
            createdAt: group.createdAt || new Date().toISOString()
          }));
        }
        
        // Koleksiyonu kaydet
        const updatedCollections = [...existingCollections, uploadedCollection];
        localStorage.setItem("collections", JSON.stringify(updatedCollections));
        
        // State'i güncelle
        setCollections(sortCollections(updatedCollections, sortOption));
        setIsFileUploadOpen(false);
        
        toast.success(
          language === "en"
            ? "Collection uploaded successfully."
            : "Koleksiyon başarıyla yüklendi."
        );
      } catch (error) {
        console.error("Upload error:", error);
        setFileUploadError(
          language === "en"
            ? "Invalid collection file. Please upload a valid JSON file."
            : "Geçersiz koleksiyon dosyası. Lütfen geçerli bir JSON dosyası yükleyin."
        );
      }
    };
    
    reader.readAsText(file);
  };

  // Dosya yükleme modülü
  const FileUploadModal = () => (
    <div className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}>
      <div className={`${currentTheme.modal} p-4 rounded-md shadow-md max-w-md w-full`}>
        <h3 className="mb-4 font-bold">
          {language === "en" ? "Upload Collection" : "Koleksiyon Yükle"}
        </h3>
        
        <div className="mb-4">
          <label className={`block ${currentTheme.text} mb-2`}>
            {language === "en" ? "Select JSON file:" : "JSON dosyası seçin:"}
          </label>
          <input
            type="file"
            accept=".json,application/json"
            onChange={handleCollectionUpload}
            className="w-full p-2 border rounded"
          />
          {fileUploadError && (
            <p className="text-red-500 mt-2 text-sm">{fileUploadError}</p>
          )}
        </div>
        
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setIsFileUploadOpen(false)}
            className={`${currentTheme.button} py-1 px-3 rounded`}
          >
            {language === "en" ? "Cancel" : "İptal"}
          </button>
        </div>
      </div>
    </div>
  );

  const CollectionGridView = ({ collections }) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-10">
      {collections.map((collection) => {
        const { groupCount, wordCount } = getCollectionStats(collection.id);
        return (
          <div
            key={collection.id}
            className={`flex flex-col justify-between p-4 ${currentTheme.componentBg} shadow-md rounded-md h-48 select-none`}
          >
            <div>
              <NavLink
                to={`/collection/${collection.id}`}
                className={`${getCollectionColor(collection)} text-4xl font-bold ${currentTheme.hover} font-mono flex items-center`}
              >
                <FaFolder />
                <span className="ml-2">
                  {collection.name.length > 18
                    ? collection.name.substring(0, 18) + "..."
                    : collection.name}
                </span>
              </NavLink>
              <div className={`mt-2 text-sm ${currentTheme.text} flex items-center`}>
                {groupCount > 0 ? (
                  <>
                    <div className="flex items-center mr-3">
                      <span className="font-medium">{groupCount}</span>
                      <span className="ml-1 opacity-75">{language === "en" ? "quizzes" : "quiz"}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="font-medium">{wordCount}</span>
                      <span className="ml-1 opacity-75">{language === "en" ? "questions" : "soru"}</span>
                    </div>
                  </>
                ) : (
                  <span className="italic opacity-75">
                    {language === "en" ? "No quizzes yet" : "Henüz quiz yok"}
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handleRenameModalOpen(collection)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaEdit />
              </button>
              <button
                onClick={() => handleColorPickerOpen(collection.id)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaPalette />
              </button>
              <button
                onClick={() => handleDownloadCollection(collection)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaDownload />
              </button>
              <button
                onClick={() => handleDelete(collection.id)}
                className="bg-red-500 font-bold text-white py-1 px-3 rounded hover:bg-red-700"
              >
                {language === "en" ? "Delete" : "Sil"}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );

  const CollectionListView = ({ collections }) => (
    <div className="flex flex-col gap-2 mt-10">
      {collections.map((collection) => {
        const { groupCount, wordCount } = getCollectionStats(collection.id);
        return (
          <div
            key={collection.id}
            className={`flex justify-between items-center p-4 ${currentTheme.componentBg} shadow-md rounded-md select-none`}
          >
            <div className="flex items-center">
              <NavLink
                to={`/collection/${collection.id}`}
                className={`${getCollectionColor(collection)} font-bold ${currentTheme.hover} font-mono flex items-center gap-2`}
              >
                <FaFolder className="text-3xl" />
                <span>{collection.name}</span>
              </NavLink>
              <div className={`ml-3 text-sm ${currentTheme.text} opacity-75`}>
                {groupCount > 0 ? (
                  <span>
                    ({groupCount} {language === "en" ? "quizzes" : "quiz"}, {wordCount} {language === "en" ? "questions" : "soru"})
                  </span>
                ) : (
                  <span className="italic">
                    ({language === "en" ? "No quizzes yet" : "Henüz quiz yok"})
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handleRenameModalOpen(collection)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaEdit />
              </button>
              <button
                onClick={() => handleColorPickerOpen(collection.id)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaPalette />
              </button>
              <button
                onClick={() => handleDownloadCollection(collection)}
                className={`${currentTheme.button} font-bold py-1 px-3 rounded hover:opacity-80`}
              >
                <FaDownload />
              </button>
              <button
                onClick={() => handleDelete(collection.id)}
                className="bg-red-500 font-bold text-white py-1 px-3 rounded hover:bg-red-700"
              >
                {language === "en" ? "Delete" : "Sil"}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );

  useEffect(() => {
    localStorage.setItem('preferredViewMode', viewMode);
  }, [viewMode]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && showConfirmation) {
        confirmDelete();
      }
    };

    if (showConfirmation) {
      window.addEventListener('keydown', handleKeyPress);
    }

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [showConfirmation]);

  const currentTheme = isDarkTheme ? themes.dark : themes.light;
  
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem('preferredViewMode', mode);
  };

  const ViewToggle = () => (
    <div className="flex gap-1">
      <button
        onClick={() => handleViewModeChange('list')}
        className={`p-2 rounded-l ${viewMode === 'list' ? currentTheme.activeViewButton : currentTheme.button}`}
      >
        <FaList />
      </button>
      <button
        onClick={() => handleViewModeChange('grid')}
        className={`p-2 rounded-r ${viewMode === 'grid' ? currentTheme.activeViewButton : currentTheme.button}`}
      >
        <FaThLarge />
      </button>
    </div>
  );

  useEffect(() => {
    const savedCollections = JSON.parse(localStorage.getItem("collections")) || [];
    const normalizedCollections = savedCollections.map(collection => ({
      ...collection,
      createdAt: collection.createdAt || new Date().toISOString()
    }));
    const sortedCollections = sortCollections(normalizedCollections, sortOption);
    setCollections(sortedCollections);
  }, [sortOption]);

  const sortCollections = (collections, option) => {
    switch(option) {
      case "name-asc":
        return [...collections].sort((a, b) => 
          a.name.localeCompare(b.name, "tr", { numeric: true })
        );
      case "name-desc":
        return [...collections].sort((a, b) => 
          b.name.localeCompare(a.name, "tr", { numeric: true })
        );
      case "date-asc":
        return [...collections].sort((a, b) => 
          new Date(a.createdAt) - new Date(b.createdAt)
        );
      case "date-desc":
        return [...collections].sort((a, b) => 
          new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "quiz-desc":
        return [...collections].sort((a, b) => {
          const aStats = getCollectionStats(a.id);
          const bStats = getCollectionStats(b.id);
          return bStats.groupCount - aStats.groupCount;
        });
      case "word-desc":
        return [...collections].sort((a, b) => {
          const aStats = getCollectionStats(a.id);
          const bStats = getCollectionStats(b.id);
          return bStats.wordCount - aStats.wordCount;
        });
      default:
        return collections;
    }
  };

  const indexOfLastCollection = currentPage * collectionsPerPage;
  const indexOfFirstCollection = indexOfLastCollection - collectionsPerPage;
  const currentCollections = collections.slice(indexOfFirstCollection, indexOfLastCollection);
  const totalPages = Math.max(1, Math.ceil(collections.length / collectionsPerPage));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (id) => {
    setShowConfirmation(true);
    setCollectionToDelete(id);
  };

  const confirmDelete = () => {
    const updatedCollections = collections.filter(
      (collection) => collection.id !== collectionToDelete
    );
    setCollections(updatedCollections);
    localStorage.setItem("collections", JSON.stringify(updatedCollections));
    setShowConfirmation(false);
    
    const newTotalPages = Math.ceil(updatedCollections.length / collectionsPerPage);
    if (currentPage > newTotalPages && newTotalPages > 0) {
      setCurrentPage(newTotalPages);
    }
    
    toast.success(
      language === "en"
        ? "Collection deleted successfully."
        : "Koleksiyon başarıyla silindi."
    );
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setCollectionToDelete(null);
  };

  const sortOptions = [
    { 
      value: "name-asc", 
      label: language === "en" ? "Name (A-Z)" : "İsim (A-Z)" 
    },
    { 
      value: "name-desc", 
      label: language === "en" ? "Name (Z-A)" : "İsim (Z-A)" 
    },
    { 
      value: "date-asc", 
      label: language === "en" ? "Date (Oldest)" : "Tarih (En Eski)" 
    },
    { 
      value: "date-desc", 
      label: language === "en" ? "Date (Newest)" : "Tarih (En Yeni)" 
    },
    { 
      value: "quiz-desc", 
      label: language === "en" ? "Most Quizzes" : "En Çok Quiz" 
    },
    { 
      value: "word-desc", 
      label: language === "en" ? "Most Words" : "En Çok Soru" 
    }
  ];

  const getCurrentSortLabel = () => {
    const currentSort = sortOptions.find(option => option.value === sortOption);
    return currentSort ? currentSort.label : sortOptions[0].label;
  };

  const Pagination = () => {
    return (
      <div className={`flex justify-center gap-2 p-4 ${currentTheme.pagination.wrapper} border-t transition-colors duration-300`}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded transition-colors duration-300 ${
            currentPage === 1
              ? currentTheme.pagination.navigation.disabled
              : currentTheme.pagination.navigation.active
          }`}
        >
          {language === "en" ? "Previous" : "Önceki"}
        </button>
        
        <div className="flex items-center gap-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded transition-colors duration-300 ${
                currentPage === index + 1
                  ? currentTheme.pagination.button.active
                  : currentTheme.pagination.button.normal
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded transition-colors duration-300 ${
            currentPage === totalPages
              ? currentTheme.pagination.navigation.disabled
              : currentTheme.pagination.navigation.active
          }`}
        >
          {language === "en" ? "Next" : "Sonraki"}
        </button>
      </div>
    );
  };

  return (
    <div className={`h-full flex flex-col ${currentTheme.background} ${currentTheme.text} transition-colors duration-300`}>
      <ToastContainer />
      {showColorPicker && (
        <ColorPicker onClose={() => setShowColorPicker(false)} />
      )}
      {showRenameModal && (
        <RenameModal />
      )}
      {isFileUploadOpen && (
        <FileUploadModal />
      )}
      <div className="flex-1 overflow-y-auto p-6">
        <div className="container mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold cursor-default font-mono select-none">
              {language === "en" ? "Collections" : "Koleksiyonlar"}
            </h1>
            
            <div className="flex items-center gap-4">
              <button
                onClick={() => setIsFileUploadOpen(true)}
                className={`${currentTheme.button} px-3 py-2 rounded flex items-center gap-2`}
              >
                <FaUpload />
                <span>{language === "en" ? "Upload Collection" : "Koleksiyon Yükle"}</span>
              </button>
              
              <ViewToggle />
              
              <div className="relative">
                <button 
                  onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
                  className={`${currentTheme.button} ${currentTheme.text} p-2 rounded flex items-center`}
                >
                  <FaSort className="mr-2" />
                  {getCurrentSortLabel()}
                </button>
                
                {isSortDropdownOpen && (
                  <div className={`absolute right-0 mt-2 w-48 ${currentTheme.dropdown} rounded shadow-lg z-10`}>
                    {sortOptions.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => {
                          setSortOption(option.value);
                          setIsSortDropdownOpen(false);
                        }}
                        className={`w-full text-left px-4 py-2 ${currentTheme.dropdownHover} ${currentTheme.text}`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {collections.length === 0 ? (
            <p>
              {language === "en"
                ? "No collections created yet."
                : "Henüz bir koleksiyon oluşturulmadı."}
            </p>
          ) : (
            viewMode === 'grid' ? (
              <CollectionGridView collections={currentCollections} />
            ) : (
              <CollectionListView collections={currentCollections} />
            )
          )}
        </div>
      </div>
      
      <Pagination />

      {showConfirmation && (
        <div className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}>
          <div className={`${currentTheme.modal} p-4 rounded-md shadow-md`}>
            <p className="mb-4">
              {language === "en"
                ? "Are you sure you want to delete this collection?"
                : "Bu koleksiyonu silmek istediğinize emin misiniz?"}
            </p>
            <div className="flex justify-end">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 mr-2"
              >
                {language === "en" ? "Yes" : "Evet"}
              </button>
              <button
                onClick={cancelDelete}
                className={`${currentTheme.button} py-1 px-3 rounded`}
              >
                {language === "en" ? "Cancel" : "İptal"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;