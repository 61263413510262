import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { GiNotebook } from "react-icons/gi";
import { FaSort, FaThLarge, FaList, FaPalette, FaSearch } from "react-icons/fa";

const CollectionDetail = ({ language, isDarkTheme }) => {
  const { id } = useParams();
  const [collections, setCollections] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("name-asc");
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const itemsPerPage = 9;
  const [viewMode, setViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem("wordGroupsViewMode");
    return savedViewMode || "grid";
  });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const themes = {
    light: {
      background: "bg-gray-200",
      text: "text-neutral-900",
      cardBg: "bg-gray-300",
      buttonPrimary: "bg-blue-500 hover:bg-blue-600",
      buttonSecondary: "bg-gray-500 hover:bg-gray-600",
      buttonDanger: "bg-red-500 hover:bg-red-600",
      buttonYellow: "bg-yellow-400 hover:bg-yellow-500 text-gray-900",
      sortButton: "bg-gray-300 hover:bg-gray-400",
      sortDropdown: "bg-gray-200",
      sortDropdownHover: "hover:bg-gray-300",
      modal: "bg-white",
      modalOverlay: "bg-black bg-opacity-50",
      textSecondary: "text-gray-600",
      hover: "hover:text-blue-500",
      activeViewButton: "bg-yellow-400 text-gray-900",
      viewButton: "bg-gray-300 hover:bg-gray-400",
      pagination: {
        wrapper: "bg-gray-300 border-neutral-300",
        button: {
          normal:
            "bg-white text-neutral-900 border border-neutral-300 hover:bg-neutral-100",
          active: "bg-blue-500 text-white border border-blue-600",
          disabled:
            "bg-gray-200 text-gray-400 border border-gray-300 cursor-not-allowed",
        },
        navigation: {
          active: "bg-blue-500 hover:bg-blue-600 text-white",
          disabled: "bg-gray-200 text-gray-400 border border-gray-300",
        },
      },
    },
    dark: {
      background: "bg-gray-800",
      text: "text-white",
      cardBg: "bg-gray-900",
      buttonPrimary: "bg-blue-500 hover:bg-blue-600",
      buttonSecondary: "bg-gray-700 hover:bg-gray-600",
      buttonDanger: "bg-red-500 hover:bg-red-600",
      buttonYellow:
        "bg-yellow-400 hover:bg-gray-950 hover:text-yellow-400 text-gray-900",
      sortButton: "bg-gray-700 hover:bg-gray-600",
      sortDropdown: "bg-gray-700",
      sortDropdownHover: "hover:bg-gray-600",
      modal: "bg-gray-900",
      modalOverlay: "bg-black bg-opacity-50",
      textSecondary: "text-gray-400",
      hover: "hover:text-yellow-400",
      activeViewButton: "bg-yellow-400 text-gray-900",
      viewButton: "bg-gray-700 hover:bg-gray-600",
      pagination: {
        wrapper: "bg-gray-800 border-gray-700",
        button: {
          normal:
            "bg-gray-700 text-white border border-gray-600 hover:bg-gray-600",
          active: "bg-yellow-500 text-gray-900 border border-yellow-600",
          disabled:
            "bg-gray-900 text-gray-600 border border-gray-800 cursor-not-allowed",
        },
        navigation: {
          active: "bg-blue-500 hover:bg-blue-600 text-white",
          disabled: "bg-gray-900 text-gray-600 border border-gray-800",
        },
      },
    },
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  // Add this new function to handle search
  const handleSearch = (term) => {
    setSearchTerm(term);

    if (!term.trim()) {
      setSearchResults([]);
      setShowSearchResults(false);
      return;
    }

    const searchTermLower = term.toLowerCase();
    
    // Search across all word groups
    const results = [];
    
    collection.wordGroups.forEach(group => {
      if (!group.words) return;
      
      group.words.forEach(word => {
        // Support both new format (question/answer) and old format (turkish/english)
        const questionText = word.question || word.turkish || "";
        const answerText = word.answer || word.english || "";
        
        const matchesQuestion = 
          questionText && 
          typeof questionText === "string" && 
          questionText.toLowerCase().includes(searchTermLower);
          
        const matchesAnswer = 
          answerText && 
          typeof answerText === "string" && 
          answerText.toLowerCase().includes(searchTermLower);
          
        if (matchesQuestion || matchesAnswer) {
          results.push({
            groupId: group.id,
            groupName: group.name,
            question: questionText,
            answer: answerText
          });
        }
      });
    });
    
    setSearchResults(results);
    setShowSearchResults(results.length > 0);
  };

  const SearchResults = () => (
    <div
      className={`${currentTheme.cardBg} rounded-lg shadow-md mt-2 mb-4 max-h-60 overflow-y-auto`}
    >
      {searchResults.length > 0 ? (
        <div className="p-4">
          {searchResults.map((result, index) => (
            <div
              key={index}
              className={`${currentTheme.cardBg} p-3 mb-2 rounded border ${
                isDarkTheme ? "border-gray-700" : "border-gray-300"
              }`}
            >
              <div className={`font-bold ${currentTheme.text}`}>
                {language === "en" ? "Question: " : "Soru: "}
                {result.question}
              </div>
              <div className={`${currentTheme.textSecondary} mt-1`}>
                {language === "en" ? "Answer: " : "Cevap: "}
                {result.answer}
              </div>
              <div className={`text-sm ${currentTheme.textSecondary} mt-2`}>
                {language === "en" ? "Found in quiz: " : "Quiz: "}
                <Link
                  to={`/quiz/${id}/${result.groupId}`}
                  className={`${
                    isDarkTheme ? "text-yellow-400" : "text-blue-600"
                  } hover:underline`}
                >
                  {result.groupName}
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 text-center">
          {language === "en"
            ? "No matching questions or answers found."
            : "Eşleşen soru veya cevap bulunamadı."}
        </div>
      )}
    </div>
  );

  const colorOptions = {
    light: [
      { name: "Default", value: "text-neutral-900" },
      { name: "Red", value: "text-red-600" },
      { name: "Blue", value: "text-blue-600" },
      { name: "Green", value: "text-green-600" },
      { name: "Purple", value: "text-purple-600" },
      { name: "Yellow", value: "text-yellow-600" },
      { name: "Pink", value: "text-pink-600" },
      { name: "Orange", value: "text-orange-600" },
    ],
    dark: [
      { name: "Default", value: "text-white" },
      { name: "Red", value: "text-red-400" },
      { name: "Blue", value: "text-blue-400" },
      { name: "Green", value: "text-green-400" },
      { name: "Purple", value: "text-purple-400" },
      { name: "Yellow", value: "text-yellow-400" },
      { name: "Pink", value: "text-pink-400" },
      { name: "Orange", value: "text-orange-400" },
    ],
  };

  const handleColorPickerOpen = (groupId) => {
    setSelectedGroupId(groupId);
    setShowColorPicker(true);
  };

  const getGroupColor = (group) => {
    const themeMode = isDarkTheme ? "dark" : "light";
    return (
      group.colors?.[themeMode] ||
      (isDarkTheme ? "text-white" : "text-neutral-900")
    );
  };

  const handleColorSelect = (color) => {
    const updatedCollections = collections.map((col) => {
      if (col.id.toString() === id) {
        return {
          ...col,
          wordGroups: col.wordGroups.map((group) => {
            if (group.id === selectedGroupId) {
              return {
                ...group,
                colors: {
                  ...(group.colors || {}),
                  [isDarkTheme ? "dark" : "light"]: color,
                },
              };
            }
            return group;
          }),
        };
      }
      return col;
    });

    setCollections(updatedCollections);
    localStorage.setItem("collections", JSON.stringify(updatedCollections));
    setShowColorPicker(false);
  };

  const ColorPicker = ({ onClose }) => (
    <div
      className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}
    >
      <div className={`${currentTheme.modal} p-4 rounded-md shadow-md`}>
        <h3 className="mb-4 font-bold">
          {language === "en" ? "Select Color" : "Renk Seç"}
        </h3>
        <div className="grid grid-cols-4 gap-2">
          {colorOptions[isDarkTheme ? "dark" : "light"].map((color) => (
            <button
              key={color.name}
              onClick={() => handleColorSelect(color.value)}
              className={`p-4 rounded ${color.value} hover:opacity-80 transition-opacity`}
            >
              <GiNotebook className="text-2xl" />
            </button>
          ))}
        </div>
        <button
          onClick={onClose}
          className={`${currentTheme.buttonSecondary} text-white mt-4 py-1 px-3 rounded w-full`}
        >
          {language === "en" ? "Cancel" : "İptal"}
        </button>
      </div>
    </div>
  );

  const collection = collections.find((col) => col.id.toString() === id);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem("wordGroupsViewMode", mode);
  };

  const ViewToggle = () => (
    <div className="flex gap-1 mr-4">
      <button
        onClick={() => handleViewModeChange("list")}
        className={`p-2 rounded-l ${viewMode === "list" ? currentTheme.activeViewButton : currentTheme.viewButton}`}
      >
        <FaList />
      </button>
      <button
        onClick={() => handleViewModeChange("grid")}
        className={`p-2 rounded-r ${viewMode === "grid" ? currentTheme.activeViewButton : currentTheme.viewButton}`}
      >
        <FaThLarge />
      </button>
    </div>
  );

  const WordGroupGridView = ({ items }) => (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 font-mono select-none">
      {items.map((group) => (
        <div
          key={group.id}
          className={`${currentTheme.cardBg} rounded-lg overflow-hidden shadow-md relative p-4`}
        >
          <Link
            to={`/quiz/${collection.id}/${group.id}`}
            className={`flex items-center text-4xl font-bold mb-2 ${getGroupColor(
              group
            )} ${currentTheme.hover} truncate`}
            title={group.name}
          >
            <GiNotebook />
            <span className="ml-2 truncate max-w-[500px] overflow-hidden text-ellipsis">
              {group.name.length > 20
                ? `${group.name.substring(0, 20)}...`
                : group.name}
            </span>
          </Link>
          <span
            className={`text-2xl font-normal ${currentTheme.textSecondary} ${currentTheme.hover} text-center mb-2`}
          >
            ({group.words.length} {language === "en" ? "questions" : "soru"})
          </span>
          <div className="flex space-x-2 mt-8 flex-wrap justify-between items-end">
            <div className="flex space-x-2">
              <button
                onClick={() => handleColorPickerOpen(group.id)}
                className={`${currentTheme.buttonSecondary} text-white py-1 px-3 rounded`}
              >
                <FaPalette />
              </button>
              <Link
                to={`/edit-word-group/${id}/${group.id}`}
                className={`${currentTheme.buttonPrimary} text-white py-1 px-3 rounded`}
              >
                {language === "en" ? "Edit" : "Düzenle"}
              </Link>
              <button
                onClick={() => handleDeleteClick(group.id)}
                className={`${currentTheme.buttonDanger} text-white py-1 px-3 rounded`}
              >
                {language === "en" ? "Delete" : "Sil"}
              </button>
            </div>
            <span
              className={`text-xs ${currentTheme.textSecondary} self-center`}
            >
              {new Date(group.createdAt).toLocaleString()}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  const WordGroupListView = ({ items }) => (
    <div className="flex flex-col gap-2 font-mono select-none">
      {items.map((group) => (
        <div
          key={group.id}
          className={`${currentTheme.cardBg} rounded-lg shadow-md p-4`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center flex-1">
              <Link
                to={`/quiz/${collection.id}/${group.id}`}
                className={`flex items-center text-2xl font-bold ${getGroupColor(
                  group
                )} ${currentTheme.hover}`}
                title={group.name}
              >
                <GiNotebook className="mr-2" />
                <span>{group.name}</span>
              </Link>
              <span className={`ml-4 ${currentTheme.textSecondary}`}>
                ({group.words.length} {language === "en" ? "questions" : "soru"})
              </span>
            </div>

            <div className="flex items-center gap-4">
              <span className={`text-sm ${currentTheme.textSecondary}`}>
                {new Date(group.createdAt).toLocaleString()}
              </span>
              <div className="flex gap-2">
                <button
                  onClick={() => handleColorPickerOpen(group.id)}
                  className={`${currentTheme.buttonSecondary} text-white py-1 px-3 rounded`}
                >
                  <FaPalette />
                </button>
                <Link
                  to={`/edit-word-group/${id}/${group.id}`}
                  className={`${currentTheme.buttonPrimary} text-white py-1 px-3 rounded`}
                >
                  {language === "en" ? "Edit" : "Düzenle"}
                </Link>
                <button
                  onClick={() => handleDeleteClick(group.id)}
                  className={`${currentTheme.buttonDanger} text-white py-1 px-3 rounded`}
                >
                  {language === "en" ? "Delete" : "Sil"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // Sorting function
  const sortWordGroups = (wordGroups, option) => {
    switch (option) {
      case "name-asc":
        return [...wordGroups].sort((a, b) =>
          a.name.localeCompare(b.name, "tr", { numeric: true })
        );
      case "name-desc":
        return [...wordGroups].sort((a, b) =>
          b.name.localeCompare(a.name, "tr", { numeric: true })
        );
      case "date-asc":
        return [...wordGroups].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      case "date-desc":
        return [...wordGroups].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      default:
        return wordGroups;
    }
  };

  useEffect(() => {
    const savedCollections =
      JSON.parse(localStorage.getItem("collections")) || [];
    setCollections(savedCollections);
  }, []);

  const sortOptions = [
    {
      value: "name-asc",
      label: language === "en" ? "Name (A-Z)" : "İsim (A-Z)",
    },
    {
      value: "name-desc",
      label: language === "en" ? "Name (Z-A)" : "İsim (Z-A)",
    },
    {
      value: "date-asc",
      label: language === "en" ? "Date (Oldest)" : "Tarih (En Eski)",
    },
    {
      value: "date-desc",
      label: language === "en" ? "Date (Newest)" : "Tarih (En Yeni)",
    },
  ];

  const getCurrentSortLabel = () => {
    const currentSort = sortOptions.find(
      (option) => option.value === sortOption
    );
    return currentSort ? currentSort.label : sortOptions[0].label;
  };

  const sortedWordGroups = collection?.wordGroups
    ? sortWordGroups(collection.wordGroups, sortOption)
    : [];

  const totalPages = Math.max(
    1,
    Math.ceil(sortedWordGroups.length / itemsPerPage)
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedWordGroups.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteClick = (groupId) => {
    setSelectedGroupId(groupId);
    setShowConfirmModal(true);
  };

  const handleDeleteConfirm = () => {
    const updatedCollections = collections.map((col) => {
      if (col.id.toString() === id) {
        col.wordGroups = col.wordGroups.filter(
          (group) => group.id !== selectedGroupId
        );
      }
      return col;
    });

    localStorage.setItem("collections", JSON.stringify(updatedCollections));
    setCollections(updatedCollections);
    setShowConfirmModal(false);
    setSelectedGroupId(null);

    const newTotalPages = Math.ceil(
      (collection.wordGroups?.length || 0) / itemsPerPage
    );
    if (currentPage > newTotalPages && newTotalPages > 0) {
      setCurrentPage(newTotalPages);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmModal(false);
    setSelectedGroupId(null);
  };

  const startMixedQuiz = () => {
    navigate(`/mixed-quiz/${id}`);
  };

  const Pagination = () => (
    <div
      className={`flex justify-center gap-2 p-4 ${currentTheme.pagination.wrapper} border-t transition-colors duration-300`}
    >
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-4 py-2 rounded transition-colors duration-300 ${
          currentPage === 1
            ? currentTheme.pagination.navigation.disabled
            : currentTheme.pagination.navigation.active
        }`}
      >
        {language === "en" ? "Previous" : "Önceki"}
      </button>

      <div className="flex items-center gap-2">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 rounded transition-colors duration-300 ${
              currentPage === index + 1
                ? currentTheme.pagination.button.active
                : currentTheme.pagination.button.normal
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-4 py-2 rounded transition-colors duration-300 ${
          currentPage === totalPages
            ? currentTheme.pagination.navigation.disabled
            : currentTheme.pagination.navigation.active
        }`}
      >
        {language === "en" ? "Next" : "Sonraki"}
      </button>
    </div>
  );

  if (!collection) {
    return (
      <p className={currentTheme.text}>
        {language === "en" ? "Collection not found." : "Koleksiyon bulunamadı."}
      </p>
    );
  }

  return (
    <div
      className={`h-full flex flex-col ${currentTheme.background} ${currentTheme.text}`}
    >
      {showColorPicker && (
        <ColorPicker onClose={() => setShowColorPicker(false)} />
      )}
      <div className="flex-1 overflow-y-auto p-6">
        <div className="container mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-5xl font-bold uppercase font-mono select-none">
              {collection.name}
            </h1>

            <div className="flex items-center">
              <ViewToggle />
              <div className="relative">
                <button
                  onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
                  className={`${currentTheme.sortButton} ${currentTheme.text} p-2 rounded flex items-center`}
                >
                  <FaSort className="mr-2" />
                  {getCurrentSortLabel()}
                </button>

                {isSortDropdownOpen && (
                  <div
                    className={`absolute right-0 mt-2 w-48 ${currentTheme.sortDropdown} rounded shadow-lg z-10`}
                  >
                    {sortOptions.map((option) => (
                      <button
                        key={option.value}
                        onClick={() => {
                          setSortOption(option.value);
                          setIsSortDropdownOpen(false);
                        }}
                        className={`w-full text-left px-4 py-2 ${currentTheme.sortDropdownHover}`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {collection.wordGroups && collection.wordGroups.length > 0 ? (
            <>
              <div className="relative mb-4">
                <div className="flex items-center">
                  <div
                    className={`relative flex-1 ${
                      isDarkTheme ? currentTheme.cardBg : "bg-white"
                    } rounded-lg shadow-sm border ${
                      isDarkTheme ? "border-gray-700" : "border-gray-300"
                    }`}
                  >
                    <FaSearch
                      className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${currentTheme.textSecondary}`}
                    />
                    <input
                      type="text"
                      placeholder={
                        language === "en"
                          ? "Search questions and answers..."
                          : "Soru ve cevapları ara..."
                      }
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className={`w-full py-2 pl-10 pr-4 rounded-lg ${
                        isDarkTheme ? currentTheme.cardBg : "bg-white"
                      } ${currentTheme.text} focus:outline-none focus:ring-2 ${
                        isDarkTheme
                          ? "focus:ring-yellow-400"
                          : "focus:ring-blue-500"
                      }`}
                    />
                  </div>
                </div>
                {showSearchResults && <SearchResults />}
              </div>
              <button
                onClick={startMixedQuiz}
                className={`${currentTheme.buttonYellow} py-2 px-4 rounded font-mono font-bold uppercase mb-4`}
              >
                {language === "en" ? "Start Mixed Quiz" : "Karma Sınav Başlat"}
              </button>
              {viewMode === "grid" ? (
                <WordGroupGridView items={currentItems} />
              ) : (
                <WordGroupListView items={currentItems} />
              )}
            </>
          ) : (
            <p className={currentTheme.textSecondary}>
              {language === "en"
                ? "There are no word groups in this collection."
                : "Bu koleksiyon kayıtlı quiz bulunmamaktadır."}
            </p>
          )}
        </div>
      </div>

      <Pagination />

      {showConfirmModal && (
        <div
          className={`fixed inset-0 flex items-center justify-center ${currentTheme.modalOverlay}`}
        >
          <div className={`${currentTheme.modal} p-6 rounded-lg shadow-lg`}>
            <p className="mb-4">
              {language === "en"
                ? "Are you sure you want to delete this word group?"
                : "Bu quizi silmek istediğinize emin misiniz?"}
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleDeleteConfirm}
                className={`${currentTheme.buttonDanger} text-white py-1 px-3 rounded mr-2`}
              >
                {language === "en" ? "Yes" : "Evet"}
              </button>
              <button
                onClick={handleDeleteCancel}
                className={`${currentTheme.buttonSecondary} text-white py-1 px-3 rounded`}
              >
                {language === "en" ? "No" : "Hayır"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionDetail;
