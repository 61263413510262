import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const MixedQuiz = ({ language, isDarkTheme }) => {
  const { id } = useParams();
  const [words, setWords] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [skippedAnswers, setSkippedAnswers] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [timeLeft, setTimeLeft] = useState(10);
  const [timerId, setTimerId] = useState(null);
  const [answerHistory, setAnswerHistory] = useState([]);
  const [settings, setSettings] = useState({
    questionLanguage: "question", // Changed from "turkish" to "question"
    timerDuration: 10
  });

  const themes = {
    light: {
      background: "bg-gray-200",
      text: "text-gray-900",
      button: "bg-gray-300 hover:bg-gray-500 shadow-md hover:text-white text-gray-900",
      skipButton: "bg-gray-400 hover:bg-gray-600 text-white",
      selectedButton: "bg-blue-500 text-white",
      progressBar: "bg-gray-300",
      progressFill: "bg-blue-500",
      circularProgress: {
        textColor: "#1F2937",
        trailColor: "#E5E7EB"
      },
      correct: "text-green-600",
      incorrect: "text-red-600",
      skipped: "text-gray-500",
      historyBackground: "bg-gray-100",
      historyText: "text-gray-800"
    },
    dark: {
      background: "bg-gray-800",
      secondaryBg: "bg-gray-800",
      text: "text-white",
      button: "bg-gray-900 hover:bg-yellow-300 hover:text-gray-900 text-white",
      skipButton: "bg-gray-700 hover:bg-gray-600 text-white",
      selectedButton: "bg-blue-500",
      progressBar: "bg-gray-200",
      progressFill: "bg-blue-500",
      circularProgress: {
        textColor: "white",
        trailColor: "transparent"
      },
      correct: "text-green-400",
      incorrect: "text-red-400",
      skipped: "text-gray-400",
      historyBackground: "bg-gray-700",
      historyText: "text-gray-100"
    }
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  useEffect(() => {
    const collections = JSON.parse(localStorage.getItem("collections")) || [];
    const collection = collections.find((col) => col.id.toString() === id);

    if (collection) {
      const allWords = collection.wordGroups.flatMap((group) => group.words);
      
      // Make sure words have consistent structure
      const normalizedWords = allWords.map(word => ({
        id: word.id || Math.floor(Math.random() * 1000000),
        question: word.question || word.turkish || "",
        answer: word.answer || word.english || ""
      }));
      
      setWords(shuffleArray(normalizedWords));
      setAnswerOptions(generateAnswerOptions(0, normalizedWords));
    }
  }, [id]);

  useEffect(() => {
    if (words.length > 0) {
      setAnswerOptions(generateAnswerOptions(currentQuestionIndex, words));
      resetTimer();
    }
  }, [currentQuestionIndex, words]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleTimeUp();
    }
  }, [timeLeft]);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const resetTimer = () => {
    clearTimer();
    setTimeLeft(settings.timerDuration);
    const id = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    setTimerId(id);
  };

  const clearTimer = () => {
    if (timerId) {
      clearInterval(timerId);
    }
  };

  // Helper functions to get the right field based on format
  const getQuestionText = (word) => {
    return word.question || word.turkish || "";
  };

  const getAnswerText = (word) => {
    return word.answer || word.english || "";
  };

  const handleAnswerSelect = (answer) => {
    const currentQuestion = words[currentQuestionIndex];
    
    // Get the correct answer accounting for both formats
    const correctAnswer = settings.questionLanguage === "question" 
      ? getAnswerText(currentQuestion) 
      : getQuestionText(currentQuestion);

    const isCorrect = answer === correctAnswer;

    setAnswerHistory(prev => [...prev, {
      questionNumber: currentQuestionIndex + 1,
      question: getQuestionText(currentQuestion),
      answer: getAnswerText(currentQuestion),
      userAnswer: answer,
      isCorrect
    }]);

    if (isCorrect) {
      setCorrectAnswers(correctAnswers + 1);
    } else {
      setIncorrectAnswers(incorrectAnswers + 1);
    }

    if (currentQuestionIndex + 1 < words.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
    } else {
      setQuizCompleted(true);
      clearTimer();
    }
  };

  const handleTimeUp = () => {
    const currentQuestion = words[currentQuestionIndex];
    
    setAnswerHistory(prev => [...prev, {
      questionNumber: currentQuestionIndex + 1,
      question: getQuestionText(currentQuestion),
      answer: getAnswerText(currentQuestion),
      userAnswer: "Boş Cevap",
      isCorrect: false,
      isSkipped: true
    }]);

    setSkippedAnswers(skippedAnswers + 1);
    
    if (currentQuestionIndex + 1 < words.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
    } else {
      setQuizCompleted(true);
      clearTimer();
    }
  };

  const handleSkipQuestion = () => {
    const currentQuestion = words[currentQuestionIndex];
    
    setAnswerHistory(prev => [...prev, {
      questionNumber: currentQuestionIndex + 1,
      question: getQuestionText(currentQuestion),
      answer: getAnswerText(currentQuestion),
      userAnswer: "Boş Cevap",
      isCorrect: false,
      isSkipped: true
    }]);

    setSkippedAnswers(skippedAnswers + 1);
    
    if (currentQuestionIndex + 1 < words.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
      resetTimer();
    } else {
      setQuizCompleted(true);
      clearTimer();
    }
  };

  const generateAnswerOptions = (index, allWords) => {
    if (!words[index]) return [];
    
    const currentWord = words[index];
    
    // Get the correct answer accounting for both formats
    const correctAnswer = settings.questionLanguage === "question"
      ? getAnswerText(currentWord)
      : getQuestionText(currentWord);

    // Tüm olası yanlış cevapları oluştur
    const potentialWrongAnswers = [];
    
    // Şu anki soruyla aynı cevaba sahip diğer soruları bul
    const similarAnswers = allWords
      .filter((word, idx) => {
        const wordAnswer = settings.questionLanguage === "question"
          ? getAnswerText(word)
          : getQuestionText(word);
          
        // Farklı kelime ama aynı cevaba sahip olanları bul
        return word.id !== currentWord.id && 
               wordAnswer.toLowerCase() === correctAnswer.toLowerCase();
      })
      .map(word => settings.questionLanguage === "question" 
                    ? getQuestionText(word) 
                    : getAnswerText(word));
    
    console.log("Benzer cevaplı sorular:", similarAnswers);
    
    // Diğer tüm benzersiz cevapları topla (aynı cevapları bir kez ekle)
    allWords.forEach(word => {
      // Şu anki kelimeyi hariç tut
      if (word.id !== currentWord.id) {
        const wordAnswer = settings.questionLanguage === "question"
          ? getAnswerText(word)
          : getQuestionText(word);
          
        // Aynı cevabı tekrar eklemeyi önle 
        if (wordAnswer.toLowerCase() !== correctAnswer.toLowerCase() && 
            !potentialWrongAnswers.some(a => a.toLowerCase() === wordAnswer.toLowerCase())) {
          potentialWrongAnswers.push(wordAnswer);
        }
      }
    });
    
    // Karıştır ve 3 yanlış cevap seç
    const shuffledWrongAnswers = shuffleArray([...potentialWrongAnswers]);
    const wrongAnswers = shuffledWrongAnswers.slice(0, 3);
    
    // Yeterli sayıda yanlış cevap yoksa, bazı genel yanlış cevaplar ekle
    while (wrongAnswers.length < 3) {
      const dummyAnswers = ["Choice A", "Choice B", "Choice C", "Choice D", "Choice E"];
      const randomDummy = dummyAnswers[Math.floor(Math.random() * dummyAnswers.length)];
      
      if (!wrongAnswers.includes(randomDummy) && randomDummy.toLowerCase() !== correctAnswer.toLowerCase()) {
        wrongAnswers.push(randomDummy);
      }
    }
    
    // Tüm seçenekleri birleştir ve karıştır
    const options = [...wrongAnswers, correctAnswer];
    return shuffleArray(options);
  };

  const handleRestartQuiz = () => {
    setCurrentQuestionIndex(0);
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    setSkippedAnswers(0);
    setQuizCompleted(false);
    setAnswerHistory([]);
    setWords(shuffleArray(words));
  };

  const getColorForTimeLeft = (timeLeft) => {
    if (timeLeft > 7) {
      return isDarkTheme ? "yellow" : "#EAB308";
    } else if (timeLeft > 3) {
      return isDarkTheme ? "orange" : "#F97316";
    } else {
      return isDarkTheme ? "red" : "#EF4444";
    }
  };

  const renderAnswerHistory = () => {
    const historyToShow = quizCompleted ? answerHistory : [answerHistory[answerHistory.length - 1]];
    
    return (
      <div className={`mt-4 p-4 rounded-lg ${currentTheme.historyBackground} bg-opacity-50 backdrop-blur-sm ${quizCompleted ? 'max-w-2xl mx-auto' : ''}`}>
        <h3 className={`text-lg font-bold mb-2 select-none ${currentTheme.historyText}`}>
          {language === "en" ? "Answer History" : "Cevap Geçmişi"}
        </h3>
        <div className="space-y-1">
          {historyToShow.map((answer, index) => (
            <div key={index} className={`flex items-center space-x-2 font-mono text-sm ${currentTheme.historyText}`}>
              <span>{answer.questionNumber}-</span>
              <span className={answer.isSkipped 
                ? currentTheme.skipped 
                : answer.isCorrect 
                  ? currentTheme.correct 
                  : currentTheme.incorrect}>
                {answer.isSkipped
                  ? (language === "en" ? "Skipped" : "Boş Bırakıldı")
                  : answer.isCorrect 
                    ? (language === "en" ? "Correct" : "Doğru") 
                    : (language === "en" ? "Incorrect" : "Yanlış")}
              </span>
              <span>
                ({language === "en" ? "Your answer" : "Cevabınız"}: {answer.isSkipped ? "Boş" : answer.userAnswer} | 
                {language === "en" ? "Correct answer" : "Doğru cevap"}: {answer.answer})
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (words.length === 0) {
    return (
      <div className={`${currentTheme.background} ${currentTheme.text} p-6 flex-1 flex items-center justify-center`}>
        <p className="text-center text-xl">
          {language === "en" 
            ? "Loading quiz questions..." 
            : "Quiz soruları yükleniyor..."}
        </p>
      </div>
    );
  }

  if (quizCompleted) {
    const totalQuestions = words.length;
    const correctPercentage = (correctAnswers / totalQuestions) * 100;
    const incorrectPercentage = (incorrectAnswers / totalQuestions) * 100;
    const skippedPercentage = (skippedAnswers / totalQuestions) * 100;
    
    // Calculate score and performance metrics
    const score = Math.round((correctAnswers / totalQuestions) * 100);
    const performanceLevel = 
      score >= 90 ? (language === "en" ? "Excellent" : "Mükemmel") :
      score >= 75 ? (language === "en" ? "Very Good" : "Çok İyi") :
      score >= 60 ? (language === "en" ? "Good" : "İyi") :
      score >= 40 ? (language === "en" ? "Average" : "Orta") :
      (language === "en" ? "Needs Improvement" : "Geliştirilmeli");
    
    return (
      <div className={`p-6 ${currentTheme.background} ${currentTheme.text} overflow-y-auto flex-1 h-full`}>
        <div className="max-w-4xl mx-auto pb-10">
          <h1 className="text-2xl font-bold text-center mt-6 mb-4 select-none">
            {language === "en" ? "Quiz Completed" : "Quiz Tamamlandı"}
          </h1>
          
          <div className={`${isDarkTheme ? 'bg-white bg-opacity-10' : 'bg-gray-700 bg-opacity-15 shadow-md'} rounded-xl p-4 mb-8 text-center`}>
            <h2 className="text-xl font-bold mb-2">
              {language === "en" ? "Your Score" : "Puanınız"}: {score}/100
            </h2>
            <p className={`text-lg font-medium ${
              score >= 75 ? (isDarkTheme ? "text-green-500" : "text-green-700") : 
              score >= 40 ? (isDarkTheme ? "text-yellow-500" : "text-yellow-700") : 
              (isDarkTheme ? "text-red-500" : "text-red-700")
            }`}>
              {performanceLevel}
            </p>
          </div>

          <div className="flex flex-wrap justify-center items-center gap-6 select-none mb-8">
            <div className="w-32 md:w-40">
              <CircularProgressbar
                value={correctPercentage}
                text={`${correctPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#22c55e" : "#15803d", // green-500 or green-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p className={`text-center mt-2 font-medium ${isDarkTheme ? "text-green-500" : "text-green-700"}`}>
                {language === "en" ? `Correct (${correctAnswers})` : `Doğru (${correctAnswers})`}
              </p>
            </div>
            <div className="w-32 md:w-40">
              <CircularProgressbar
                value={incorrectPercentage}
                text={`${incorrectPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#ef4444" : "#b91c1c", // red-500 or red-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p className={`text-center mt-2 font-medium ${isDarkTheme ? "text-red-500" : "text-red-700"}`}>
                {language === "en" ? `Incorrect (${incorrectAnswers})` : `Yanlış (${incorrectAnswers})`}
              </p>
            </div>
            <div className="w-32 md:w-40">
              <CircularProgressbar
                value={skippedPercentage}
                text={`${skippedPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#6b7280" : "#374151", // gray-500 or gray-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p className={`text-center mt-2 font-medium ${isDarkTheme ? "text-gray-400" : "text-gray-600"}`}>
                {language === "en" ? `Skipped (${skippedAnswers})` : `Boş (${skippedAnswers})`}
              </p>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div className={`${isDarkTheme ? 'bg-white bg-opacity-5' : 'bg-gray-300 bg-opacity-50 shadow-md'} rounded-lg p-4`}>
              <h3 className="font-bold mb-3 text-center">
                {language === "en" ? "Quiz Statistics" : "Quiz İstatistikleri"}
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>{language === "en" ? "Total Questions" : "Toplam Soru"}</span>
                  <span className="font-medium">{totalQuestions}</span>
                </div>
                <div className="flex justify-between">
                  <span>{language === "en" ? "Accuracy Rate" : "Doğruluk Oranı"}</span>
                  <span className="font-medium">{correctPercentage.toFixed(1)}%</span>
                </div>
                <div className="flex justify-between">
                  <span>{language === "en" ? "Completion Status" : "Tamamlama Durumu"}</span>
                  <span className={`font-medium ${isDarkTheme ? "text-green-500" : "text-green-700"}`}>
                    {language === "en" ? "Completed" : "Tamamlandı"}
                  </span>
                </div>
              </div>
            </div>
            
            <div className={`${isDarkTheme ? 'bg-white bg-opacity-5' : 'bg-gray-300 bg-opacity-50 shadow-md'} rounded-lg p-4`}>
              <h3 className="font-bold mb-3 text-center">
                {language === "en" ? "Performance Analysis" : "Performans Analizi"}
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Correct Answers" : "Doğru Cevaplar"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-green-500' : 'bg-green-700'} h-2.5 rounded-full`} style={{ width: `${correctPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-green-500' : 'text-green-700'} font-medium`}>{correctAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Incorrect Answers" : "Yanlış Cevaplar"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-red-500' : 'bg-red-700'} h-2.5 rounded-full`} style={{ width: `${incorrectPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-red-500' : 'text-red-700'} font-medium`}>{incorrectAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Skipped Questions" : "Boş Bırakılan"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-gray-500' : 'bg-gray-700'} h-2.5 rounded-full`} style={{ width: `${skippedPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-gray-400' : 'text-gray-600'} font-medium`}>{skippedAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between pt-1">
                  <span>{language === "en" ? "Performance Level" : "Performans Seviyesi"}</span>
                  <span className={`font-medium ${
                    score >= 75 ? (isDarkTheme ? "text-green-500" : "text-green-700") : 
                    score >= 40 ? (isDarkTheme ? "text-yellow-500" : "text-yellow-700") : 
                    (isDarkTheme ? "text-red-500" : "text-red-700")
                  }`}>{performanceLevel}</span>
                </div>
              </div>
            </div>
          </div>
          
          <p className="text-center select-none font-medium text-sm mb-6">
            {language === "en"
              ? `You've completed the quiz with ${correctAnswers} correct answers out of ${totalQuestions} questions.`
              : `${totalQuestions} sorudan ${correctAnswers} tanesini doğru cevapladınız.`}
          </p>
          
          {renderAnswerHistory()}
          
          <div className="flex justify-center mt-8">
            <button
              onClick={handleRestartQuiz}
              className={`${currentTheme.button} text-lg font-medium select-none py-2 px-6 rounded-lg`}
            >
              {language === "en" ? "Restart Quiz" : "Quizi Yeniden Başlat"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${currentTheme.background} ${currentTheme.text} overflow-y-auto flex-1 h-full`}>
      <div className="container mx-auto p-6 pb-10">
        <div className="w-full max-w-md mx-auto mb-8 select-none">
          <div className="w-48 sm:w-72 mx-auto">
            <CircularProgressbar
              value={(timeLeft / settings.timerDuration) * 100}
              maxValue={100}
              text={`${timeLeft}s`}
              styles={buildStyles({
                textColor: currentTheme.circularProgress.textColor,
                pathColor: getColorForTimeLeft(timeLeft),
                trailColor: currentTheme.circularProgress.trailColor,
              })}
            />
          </div>
          <div className={`w-full h-4 ${currentTheme.progressBar} rounded-full mt-4`}>
            <div
              className={`h-full ${currentTheme.progressFill} rounded-full`}
              style={{
                width: `${((currentQuestionIndex + 1) / words.length) * 100}%`,
              }}
            ></div>
          </div>
          <h1 className="flex justify-center items-center text-2xl font-bold mt-4 font-mono select-none">
            {language === "en"
              ? `Question ${currentQuestionIndex + 1}/${words.length}`
              : `Soru ${currentQuestionIndex + 1}/${words.length}`}
          </h1>
        </div>
        <h2 className="text-2xl font-bold mt-4 mb-4 font-mono select-none justify-center flex underline">
          {settings.questionLanguage === "question" 
            ? getQuestionText(words[currentQuestionIndex]) 
            : getAnswerText(words[currentQuestionIndex]) || ""}
        </h2>
        <div className="flex flex-col space-y-4">
          {answerOptions.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswerSelect(option)}
              className={`${currentTheme.button} text-xl font-bold select-none font-mono py-2 px-4 rounded ${
                selectedAnswer === option && currentTheme.selectedButton
              }`}
              disabled={selectedAnswer !== ""}
            >
              {option}
            </button>
          ))}
          
          <button
            onClick={handleSkipQuestion}
            className={`${currentTheme.skipButton} text-xl font-bold select-none font-mono py-2 px-4 rounded`}
            disabled={selectedAnswer !== ""}
          >
            {language === "en" ? "Skip Question" : "Boş Bırak"}
          </button>
        </div>
        {answerHistory.length > 0 && renderAnswerHistory()}
      </div>
    </div>
  );
};

export default MixedQuiz;