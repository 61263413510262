import React, { useState, useEffect } from "react";
import { FaBook, FaListAlt, FaPuzzlePiece, FaQuestion, FaGraduationCap, FaCog, FaChevronDown, FaChevronUp } from "react-icons/fa";

const Guide = ({ language, isDarkTheme }) => {
  const [openSection, setOpenSection] = useState(null);

  // Set page color when first loaded or when theme changes
  useEffect(() => {
    const bgColor = isDarkTheme ? '#111827' : 'white';
    document.documentElement.style.backgroundColor = bgColor;
    document.body.style.backgroundColor = bgColor;
    
    // Clean up when component unmounts
    return () => {
      document.documentElement.style.backgroundColor = '';
      document.body.style.backgroundColor = '';
    };
  }, [isDarkTheme]);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const themes = {
    light: {
      background: "bg-gray-100",
      card: "bg-white",
      text: "text-gray-800",
      title: "text-gray-900",
      subtitle: "text-gray-700",
      accordion: "bg-gray-200 hover:bg-gray-300",
      accordionActive: "bg-blue-100",
      border: "border-gray-300",
      icon: "text-blue-600",
    },
    dark: {
      background: "bg-gray-900",
      card: "bg-gray-800",
      text: "text-gray-200",
      title: "text-white",
      subtitle: "text-gray-300",
      accordion: "bg-gray-700 hover:bg-gray-600",
      accordionActive: "bg-blue-900",
      border: "border-gray-700",
      icon: "text-blue-400",
    },
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  // More robust solution using CSS variables
  const backgroundColor = isDarkTheme ? '#111827' : 'white';
  const textColor = isDarkTheme ? '#e5e7eb' : '#1f2937';

  // Inline CSS styles to avoid issues on different screen sizes
  const contentStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    borderRadius: '0 0 0.375rem 0.375rem',
  };

  // Style for headings and list items
  const headingStyle = {
    color: isDarkTheme ? '#e5e7eb' : '#111827',
  };

  const sections = [
    {
      title: language === "en" ? "Introduction & Purpose" : "Giriş ve Amaç",
      icon: <FaBook className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <p className="mb-4">
              Welcome to QuizYap.com, your personal quiz platform! This application is designed to help you create and take interactive quizzes on various subjects with customizable questions and answers.
            </p>
            <p className="mb-4">
              <strong>Main Purpose:</strong> QuizYap.com enables you to create, organize, and take quizzes at your own pace. You can track your progress, test your knowledge through various quiz formats, and customize your learning experience.
            </p>
            <p>
              Whether you're a student preparing for exams, a teacher creating educational content, or simply a quiz enthusiast, QuizYap.com provides the tools you need to create engaging quizzes and test your knowledge effectively.
            </p>
          </div>
        ) : (
          <div>
            <p className="mb-4">
              QuizYap.com'a hoş geldiniz, kişisel quiz platformunuz! Bu uygulama, çeşitli konularda özelleştirilebilir sorular ve cevaplarla interaktif sınavlar oluşturmanıza ve almanıza yardımcı olmak için tasarlanmıştır.
            </p>
            <p className="mb-4">
              <strong>Ana Amaç:</strong> QuizYap.com, kendi hızınızda sınavlar oluşturmanıza, düzenlemenize ve almanıza olanak tanır. İlerlemenizi takip edebilir, çeşitli sınav formatları aracılığıyla bilginizi test edebilir ve öğrenme deneyiminizi özelleştirebilirsiniz.
            </p>
            <p>
              İster sınavlara hazırlanan bir öğrenci, ister eğitim içeriği oluşturan bir öğretmen, ister sadece bir quiz meraklısı olun, QuizYap.com ilgi çekici sınavlar oluşturmanız ve bilginizi etkili bir şekilde test etmeniz için ihtiyacınız olan araçları sunar.
            </p>
          </div>
        ),
    },
    {
      title: language === "en" ? "Collections Management" : "Koleksiyon Yönetimi",
      icon: <FaListAlt className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Creating Collections</h4>
            <p className="mb-3">
              Collections are the main organizational units for your quizzes. To create a new collection:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>Navigate to the Collections page from the main menu</li>
              <li>Enter a name for your collection</li>
              <li>Click "Save"</li>
            </ol>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Managing Collections</h4>
            <p className="mb-3">
              You can manage your collections in several ways:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Toggle between grid and list views for better organization</li>
              <li>Sort collections by name, creation date, or size</li>
              <li>Rename collections by clicking the edit button</li>
              <li>Assign custom colors to collections for visual organization</li>
              <li>Export collections to share with others</li>
              <li>Import collections from files</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Managing Question Groups</h4>
            <p className="mb-3">
              Each collection can contain multiple question groups. To add a question group:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>Open a collection by clicking on it</li>
              <li>Click "Add Question Group"</li>
              <li>Give the group a name</li>
              <li>Start adding questions and answers</li>
            </ol>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Editing and Deleting</h4>
            <p className="mb-2">
              You can edit or delete collections and question groups using the respective buttons next to each item. Your changes are automatically saved to your browser's local storage.
            </p>
          </div>
        ) : (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Koleksiyon Oluşturma</h4>
            <p className="mb-3">
              Koleksiyonlar, quizleriniz için ana organizasyon birimleridir. Yeni bir koleksiyon oluşturmak için:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>Ana menüden Koleksiyonlar sayfasına gidin</li>
              <li>Koleksiyonunuz için bir isim girin</li>
              <li>"Kayıt Et"e tıklayın</li>
            </ol>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Koleksiyonları Yönetme</h4>
            <p className="mb-3">
              Koleksiyonlarınızı çeşitli şekillerde yönetebilirsiniz:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Daha iyi organizasyon için ızgara ve liste görünümleri arasında geçiş yapın</li>
              <li>Koleksiyonları ada, oluşturma tarihine veya boyuta göre sıralayın</li>
              <li>Düzenleme düğmesine tıklayarak koleksiyonları yeniden adlandırın</li>
              <li>Görsel organizasyon için koleksiyonlara özel renkler atayın</li>
              <li>Başkalarıyla paylaşmak için koleksiyonları dışa aktarın</li>
              <li>Dosyalardan koleksiyonları içe aktarın</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Soru Gruplarını Yönetme</h4>
            <p className="mb-3">
              Her koleksiyon birden fazla soru grubu içerebilir. Soru grubu eklemek için:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>Üzerine tıklayarak bir koleksiyonu açın</li>
              <li>"Soru Grubu Ekle"ye tıklayın</li>
              <li>Gruba bir isim verin</li>
              <li>Sorular ve cevaplar eklemeye başlayın</li>
            </ol>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Düzenleme ve Silme</h4>
            <p className="mb-2">
              Her öğenin yanındaki ilgili düğmeleri kullanarak koleksiyonları ve soru gruplarını düzenleyebilir veya silebilirsiniz. Değişiklikleriniz otomatik olarak tarayıcınızın yerel depolama alanına kaydedilir.
            </p>
          </div>
        ),
    },
    {
      title: language === "en" ? "Quiz Features" : "Sınav Özellikleri",
      icon: <FaQuestion className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Regular Quizzes</h4>
            <p className="mb-3">
              Regular quizzes test your knowledge on a specific question group:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Open a collection and select a question group</li>
              <li>Click the "Start Quiz" button</li>
              <li>You'll be presented with questions</li>
              <li>Select the correct answer from the given options</li>
              <li>Each question has a 10-second time limit</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Mixed Quizzes</h4>
            <p className="mb-3">
              Mixed quizzes combine questions from all groups in a collection:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>From the collection page, click "Start Mixed Quiz"</li>
              <li>This will include all questions from all question groups in that collection</li>
              <li>Great for comprehensive review after studying individual groups</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Quiz Results</h4>
            <p className="mb-2">
              After completing a quiz, you'll see detailed results including:
            </p>
            <ul className="list-disc pl-5 mb-2">
              <li>Your score and performance rating</li>
              <li>Number of correct and incorrect answers</li>
              <li>A complete history of your answers</li>
              <li>Option to restart the quiz</li>
            </ul>
          </div>
        ) : (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Normal Sınavlar</h4>
            <p className="mb-3">
              Normal sınavlar, belirli bir soru grubundaki bilginizi test eder:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Bir koleksiyonu açın ve bir soru grubu seçin</li>
              <li>"Sınavı Başlat" düğmesine tıklayın</li>
              <li>Size sorular sunulacak</li>
              <li>Verilen seçenekler arasından doğru cevabı seçin</li>
              <li>Her sorunun 10 saniyelik bir zaman sınırı vardır</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Karışık Sınavlar</h4>
            <p className="mb-3">
              Karışık sınavlar, bir koleksiyondaki tüm gruplardan soruları birleştirir:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Koleksiyon sayfasından "Karışık Sınavı Başlat"a tıklayın</li>
              <li>Bu, o koleksiyondaki tüm soru gruplarından tüm soruları içerecektir</li>
              <li>Bireysel grupları çalıştıktan sonra kapsamlı inceleme için mükemmeldir</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Sınav Sonuçları</h4>
            <p className="mb-2">
              Bir sınavı tamamladıktan sonra şunları içeren ayrıntılı sonuçları göreceksiniz:
            </p>
            <ul className="list-disc pl-5 mb-2">
              <li>Puanınız ve performans dereceniz</li>
              <li>Doğru ve yanlış cevap sayısı</li>
              <li>Cevaplarınızın tam geçmişi</li>
              <li>Sınavı yeniden başlatma seçeneği</li>
            </ul>
          </div>
        ),
    },
    {
      title: language === "en" ? "Learning Strategies" : "Öğrenme Stratejileri",
      icon: <FaPuzzlePiece className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Effective Study Methods</h4>
            <p className="mb-3">
              To maximize your learning with QuizYap.com, consider these strategies:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Create focused collections:</strong> Organize questions by topics, difficulty, or categories</li>
              <li><strong>Regular practice:</strong> Take quizzes frequently rather than cramming</li>
              <li><strong>Spaced repetition:</strong> Review question groups periodically, focusing more on difficult topics</li>
              <li><strong>Progressive learning:</strong> Master smaller question groups before taking mixed quizzes</li>
              <li><strong>Apply knowledge:</strong> Connect quiz content to real-world applications</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Recommended Study Schedule</h4>
            <p className="mb-2">
              A sample study schedule might look like this:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>Create or select a collection with 5-10 question groups</li>
              <li>Study one question group per day</li>
              <li>Take a quiz for that group immediately after studying</li>
              <li>Review previous day's group briefly before starting a new one</li>
              <li>At the end of the week, take mixed quizzes to test retention</li>
              <li>Return to difficult questions identified in quizzes for additional practice</li>
            </ol>
          </div>
        ) : (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Etkili Çalışma Yöntemleri</h4>
            <p className="mb-3">
              QuizYap.com ile öğrenmenizi en üst düzeye çıkarmak için şu stratejileri düşünün:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Odaklanmış koleksiyonlar oluşturun:</strong> Soruları konulara, zorluklara veya kategorilere göre düzenleyin</li>
              <li><strong>Düzenli pratik:</strong> Sıkıştırmak yerine sık sık quiz yapın</li>
              <li><strong>Aralıklı tekrar:</strong> Soru gruplarını periyodik olarak gözden geçirin, zor konulara daha fazla odaklanın</li>
              <li><strong>Aşamalı öğrenme:</strong> Karışık sınavlara geçmeden önce daha küçük soru gruplarına hakim olun</li>
              <li><strong>Bilgiyi uygulayın:</strong> Quiz içeriğini gerçek dünya uygulamalarıyla ilişkilendirin</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Önerilen Çalışma Programı</h4>
            <p className="mb-2">
              Örnek bir çalışma programı şöyle görünebilir:
            </p>
            <ol className="list-decimal pl-5 mb-4">
              <li>5-10 soru grubu içeren bir koleksiyon oluşturun veya seçin</li>
              <li>Günde bir soru grubu çalışın</li>
              <li>Çalıştıktan hemen sonra o grup için bir sınav yapın</li>
              <li>Yeni bir gruba başlamadan önce önceki günün grubunu kısaca gözden geçirin</li>
              <li>Haftanın sonunda, hatırlama düzeyinizi test etmek için karışık sınavlar yapın</li>
              <li>Sınavlarda belirlenen zor sorulara ek pratik için geri dönün</li>
            </ol>
          </div>
        ),
    },
    {
      title: language === "en" ? "Tips & Advanced Features" : "İpuçları ve Gelişmiş Özellikler",
      icon: <FaGraduationCap className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Power User Tips</h4>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Keyboard shortcuts:</strong> Use Tab and Enter keys to quickly navigate while adding questions</li>
              <li><strong>View modes:</strong> Switch between grid and list views on the Collections page</li>
              <li><strong>Quiz sharing:</strong> Export and import collections to share with others</li>
              <li><strong>Custom colors:</strong> Use color coding for different question categories or difficulty levels</li>
              <li><strong>Sorting options:</strong> Sort collections by name, creation date, or size</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Study Techniques</h4>
            <p className="mb-3">
              Enhance your quiz performance with these techniques:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Active recall:</strong> Test yourself frequently rather than just reading material</li>
              <li><strong>Visualization:</strong> Create mental images that represent complex concepts</li>
              <li><strong>Categorization:</strong> Group related questions together thematically</li>
              <li><strong>Practice tests:</strong> Take timed quizzes to simulate test conditions</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Data Management</h4>
            <p className="mb-2">
              QuizYap.com stores your data in your browser's local storage. To avoid data loss:
            </p>
            <ul className="list-disc pl-5">
              <li>Use the same browser for consistent access</li>
              <li>Avoid clearing browser data without backing up</li>
              <li>Consider exporting important collections periodically</li>
              <li>Import collections from files when needed</li>
            </ul>
          </div>
        ) : (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Uzman Kullanıcı İpuçları</h4>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Klavye kısayolları:</strong> Soru eklerken hızlı gezinmek için Tab ve Enter tuşlarını kullanın</li>
              <li><strong>Görünüm modları:</strong> Koleksiyonlar sayfasında ızgara ve liste görünümleri arasında geçiş yapın</li>
              <li><strong>Quiz paylaşımı:</strong> Başkalarıyla paylaşmak için koleksiyonları dışa ve içe aktarın</li>
              <li><strong>Özel renkler:</strong> Farklı soru kategorileri veya zorluk seviyeleri için renk kodlaması kullanın</li>
              <li><strong>Sıralama seçenekleri:</strong> Koleksiyonları ada, oluşturma tarihine veya boyuta göre sıralayın</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Çalışma Teknikleri</h4>
            <p className="mb-3">
              Bu tekniklerle quiz performansınızı geliştirin:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li><strong>Aktif hatırlama:</strong> Sadece materyal okumak yerine kendinizi sık sık test edin</li>
              {/* <li><strong>Görselleştirme:</strong> Karmaşık kavramları temsil eden zihinsel görüntüler oluşturun</li> */}
              <li><strong>Kategorilendirme:</strong> İlgili soruları tematik olarak gruplandırın</li>
              <li><strong>Pratik testler:</strong> Test koşullarını simüle etmek için zamanlı quizler yapın</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Veri Yönetimi</h4>
            <p className="mb-2">
              QuizYap.com verilerinizi tarayıcınızın yerel depolama alanında saklar. Veri kaybını önlemek için:
            </p>
            <ul className="list-disc pl-5">
              <li>Tutarlı erişim için aynı tarayıcıyı kullanın</li>
              <li>Yedeklemeden tarayıcı verilerini temizlemekten kaçının</li>
              <li>Önemli koleksiyonları periyodik olarak dışa aktarmayı düşünün</li>
              <li>Gerektiğinde dosyalardan koleksiyonları içe aktarın</li>
            </ul>
          </div>
        ),
    },
    {
      title: language === "en" ? "Customization & Settings" : "Özelleştirme ve Ayarlar",
      icon: <FaCog className={`${currentTheme.icon} text-xl mr-2`} />,
      content:
        language === "en" ? (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Theme Customization</h4>
            <p className="mb-3">
              You can switch between light and dark themes:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Click the theme toggle in the navigation bar</li>
              <li>Your preference will be saved for future sessions</li>
              <li>Dark theme is recommended for low-light environments and reduced eye strain</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Language Settings</h4>
            <p className="mb-3">
              QuizYap.com supports multiple interface languages:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Your language preference is saved automatically</li>
              <li>The interface will update to your selected language</li>
            </ul>
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Collection Appearance</h4>
            <p className="mb-2">
              Customize how collections appear:
            </p>
            <ul className="list-disc pl-5">
              <li>Assign custom colors to collections for visual organization</li>
              <li>Toggle between grid and list views based on your preference</li>
              <li>Sort collections by name, creation date, or size</li>
              <li>Your view preferences are saved for future sessions</li>
            </ul>
          </div>
        ) : (
          <div>
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Tema Özelleştirme</h4>
            <p className="mb-3">
              Açık ve koyu temalar arasında geçiş yapabilirsiniz:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Gezinme çubuğundaki tema değiştirme düğmesine tıklayın</li>
              <li>Tercihiniz gelecek oturumlar için kaydedilecektir</li>
              <li>Koyu tema, düşük ışıklı ortamlar ve göz yorgunluğunu azaltmak için önerilir</li>
            </ul>
            
            {/* <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Dil Ayarları</h4>
            <p className="mb-3">
              QuizYap.com birden fazla arayüz dilini destekler:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>Dil tercihiniz otomatik olarak kaydedilir</li>
              <li>Arayüz seçtiğiniz dile güncellenecektir</li>
            </ul> */}
            
            <h4 className={`${currentTheme.subtitle} font-bold mb-2`}>Koleksiyon Görünümü</h4>
            <p className="mb-2">
              Koleksiyonların görünümünü özelleştirin:
            </p>
            <ul className="list-disc pl-5">
              <li>Görsel organizasyon için koleksiyonlara özel renkler atayın</li>
              <li>Tercihinize göre ızgara ve liste görünümleri arasında geçiş yapın</li>
              <li>Koleksiyonları ada, oluşturma tarihine veya boyuta göre sıralayın</li>
              <li>Görünüm tercihleriniz gelecek oturumlar için kaydedilir</li>
            </ul>
          </div>
        ),
    },
  ];

  return (
    <div className={`${currentTheme.background} guide-wrapper py-8 px-4`}>
      <div className={`max-w-4xl mx-auto ${currentTheme.card} rounded-lg shadow-2xl p-6 border-1`}>
        <h1 className={`${currentTheme.title} text-3xl font-bold mb-6 text-center`}>
          {language === "en" ? "QuizYap.com User Guide" : "QuizYap.com Kullanım Kılavuzu"}
        </h1>
        
        <p className={`${currentTheme.text} mb-8 text-center`}>
          {language === "en" 
            ? "This guide will help you get the most out of QuizYap.com. Click on each section to expand and learn more."
            : "Bu kılavuz, QuizYap.com'dan en iyi şekilde yararlanmanıza yardımcı olacaktır. Genişletmek ve daha fazla bilgi edinmek için her bölüme tıklayın."}
        </p>
        
        <div className="space-y-4">
          {sections.map((section, index) => (
            <div key={index} className={`${currentTheme.border} border rounded-lg overflow-hidden`}>
              <button
                className={`w-full ${
                  openSection === index ? currentTheme.accordionActive : currentTheme.accordion
                } p-4 text-left flex items-center justify-between transition-colors duration-200`}
                onClick={() => toggleSection(index)}
              >
                <div className="flex items-center">
                  {section.icon}
                  <span className={`${currentTheme.title} font-bold text-lg`}>{section.title}</span>
                </div>
                {openSection === index ? (
                  <FaChevronUp className={currentTheme.text} />
                ) : (
                  <FaChevronDown className={currentTheme.text} />
                )}
              </button>
              
              {openSection === index && (
                <div className="accordion-content p-4" style={contentStyle}>
                  {section.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Global style to ensure all content elements have correct background */}
      <style jsx global>{`
        html, body {
          background-color: ${backgroundColor} !important;
          margin: 0;
          padding: 0;
          min-height: 100vh;
          height: 100%;
        }
        
        #root, .guide-wrapper {
          min-height: 100vh;
          background-color: ${backgroundColor} !important;
        }
        
        .accordion-content {
          background-color: ${backgroundColor} !important;
        }
        
        .accordion-content h4 {
          color: ${isDarkTheme ? '#e5e7eb' : '#111827'} !important;
        }
        
        .accordion-content p, 
        .accordion-content li, 
        .accordion-content ul, 
        .accordion-content ol,
        .accordion-content strong {
          color: ${textColor} !important;
          background-color: ${backgroundColor} !important;
        }
        
        .accordion-content div {
          background-color: ${backgroundColor} !important;
        }
      `}</style>
    </div>
  );
};

export default Guide; 