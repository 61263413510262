import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Quiz = ({ language, isDarkTheme }) => {
  const { collectionId, groupId } = useParams();
  const [wordGroup, setWordGroup] = useState(null);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [skippedAnswers, setSkippedAnswers] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [timeLeft, setTimeLeft] = useState(10);
  const [timerId, setTimerId] = useState(null);
  const [answerHistory, setAnswerHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [error, setError] = useState("");
  const [settings, setSettings] = useState({
    questionLanguage: "turkish",
    checkType: "exact",
    partialMatchThreshold: 0.7,
    soundEffects: true,
    randomizeOrder: true,
    wordCount: 10,
    timerEnabled: true,
    timerDuration: 10,
  });
  const [quizWords, setQuizWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [timerValue, setTimerValue] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");

  const themes = {
    light: {
      background: "bg-gray-200",
      text: "text-gray-900",
      button:
        "bg-gray-300 hover:bg-gray-500 shadow-md hover:text-white text-gray-900",
      skipButton: "bg-gray-400 hover:bg-gray-600 text-white",
      progressBar: "bg-gray-300",
      progressFill: "bg-blue-500",
      circularProgress: {
        textColor: "#1F2937",
        trailColor: "#E5E7EB",
      },
      correct: "text-green-600",
      incorrect: "text-red-600",
      skipped: "text-gray-500",
      historyBackground: "bg-gray-100",
      historyText: "text-gray-800",
    },
    dark: {
      background: "bg-gray-800",
      text: "text-white",
      button: "bg-gray-900 hover:bg-yellow-300 hover:text-gray-900 text-white",
      skipButton: "bg-gray-700 hover:bg-gray-600 text-white",
      progressBar: "bg-gray-200",
      progressFill: "bg-blue-500",
      circularProgress: {
        textColor: "white",
        trailColor: "transparent",
      },
      correct: "text-green-400",
      incorrect: "text-red-400",
      skipped: "text-gray-400",
      historyBackground: "bg-gray-700",
      historyText: "text-gray-100",
    },
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    const collections = JSON.parse(localStorage.getItem("collections")) || [];
    const collection = collections.find(
      (col) => col.id === parseInt(collectionId)
    );
    if (collection) {
      const foundWordGroup = collection.wordGroups.find(
        (group) => group.id === parseInt(groupId)
      );

      if (foundWordGroup) {
        setWordGroup(foundWordGroup);
        const shuffled = shuffleArray(foundWordGroup.words);
        setShuffledWords(shuffled);
        setAnswerOptions(generateAnswerOptions(0, shuffled));
      }
    }
  }, [collectionId, groupId]);

  useEffect(() => {
    if (shuffledWords.length > 0) {
      setAnswerOptions(
        generateAnswerOptions(currentQuestionIndex, shuffledWords)
      );
      resetTimer();
    }
  }, [currentQuestionIndex, shuffledWords]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleTimeUp();
    }
  }, [timeLeft]);

  const resetTimer = () => {
    clearTimer();
    setTimeLeft(10);
    const id = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    setTimerId(id);
  };

  const clearTimer = () => {
    if (timerId) {
      clearInterval(timerId);
    }
  };

  const handleSkipQuestion = () => {
    clearTimer();

    const currentQuestion = shuffledWords[currentQuestionIndex];
    setAnswerHistory((prev) => [
      ...prev,
      {
        questionNumber: currentQuestionIndex + 1,
        question: getQuestionText(currentQuestion),
        answer: getAnswerText(currentQuestion),
        userAnswer: "",
        isCorrect: false,
        isSkipped: true,
      },
    ]);

    setIncorrectAnswers(incorrectAnswers + 1);

    if (currentQuestionIndex + 1 < shuffledWords.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
      resetTimer();
    } else {
      setQuizCompleted(true);
    }
  };

  const handleAnswerSelect = (answer) => {
    const isCorrect = answer === generateCorrectAnswer(currentQuestionIndex);
    const currentQuestion = shuffledWords[currentQuestionIndex];

    setAnswerHistory((prev) => [
      ...prev,
      {
        questionNumber: currentQuestionIndex + 1,
        question: getQuestionText(currentQuestion),
        answer: getAnswerText(currentQuestion),
        userAnswer: answer,
        isCorrect,
        isSkipped: false,
      },
    ]);

    if (isCorrect) {
      setCorrectAnswers(correctAnswers + 1);
    } else {
      setIncorrectAnswers(incorrectAnswers + 1);
    }

    if (currentQuestionIndex + 1 < shuffledWords.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
    } else {
      setQuizCompleted(true);
      clearTimer();
    }
  };

  const handleTimeUp = () => {
    handleSkipQuestion();
  };

  const generateCorrectAnswer = (index) => {
    return getAnswerText(shuffledWords[index]);
  };

  const generateAnswerOptions = (index, words) => {
    if (!words || words.length === 0) {
      return [];
    }

    const currentWord = words[index];
    const correctAnswer = getAnswerText(currentWord);
    
    // Tüm olası yanlış cevapları oluştur
    const potentialWrongAnswers = [];
    
    // Şu anki soruyla aynı cevaba sahip diğer soruları bul
    const similarAnswers = words
      .filter((word, idx) => 
        idx !== index && 
        getAnswerText(word).toLowerCase() === correctAnswer.toLowerCase()
      )
      .map(word => getQuestionText(word));
    
    console.log("Benzer cevaplı sorular:", similarAnswers);
    
    // Diğer tüm cevapları topla
    words.forEach((word, idx) => {
      // Şu anki kelimeyi hariç tut
      if (idx !== index) {
        const answer = getAnswerText(word);
        // Aynı cevabı tekrar eklemeyi önle
        if (answer.toLowerCase() !== correctAnswer.toLowerCase() && 
            !potentialWrongAnswers.some(a => a.toLowerCase() === answer.toLowerCase())) {
          potentialWrongAnswers.push(answer);
        }
      }
    });
    
    // Karıştır ve 3 yanlış cevap seç
    const shuffledWrongAnswers = shuffleArray([...potentialWrongAnswers]);
    const wrongAnswers = shuffledWrongAnswers.slice(0, 3);
    
    // Yeterli sayıda yanlış cevap yoksa, bazı genel yanlış cevaplar ekle
    while (wrongAnswers.length < 3) {
      const dummyAnswers = ["Placeholder Answer 1", "Placeholder Answer 2", "Placeholder Answer 3", "Placeholder Answer 4"];
      const randomDummy = dummyAnswers[Math.floor(Math.random() * dummyAnswers.length)];
      
      if (!wrongAnswers.includes(randomDummy) && randomDummy.toLowerCase() !== correctAnswer.toLowerCase()) {
        wrongAnswers.push(randomDummy);
      }
    }
    
    // Tüm seçenekleri birleştir ve karıştır
    const options = [...wrongAnswers, correctAnswer];
    return shuffleArray(options);
  };

  const getColorForTimeLeft = (timeLeft) => {
    if (timeLeft > 7) {
      return isDarkTheme ? "yellow" : "#EAB308";
    } else if (timeLeft > 3) {
      return isDarkTheme ? "orange" : "#F97316";
    } else {
      return isDarkTheme ? "red" : "#EF4444";
    }
  };

  const renderPaginationControls = () => {
    const totalPages = Math.ceil(answerHistory.length / itemsPerPage);

    return (
      <div className="flex justify-center items-center space-x-2 mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`${currentTheme.button} px-3 py-1 rounded ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {language === "en" ? "Previous" : "Önceki"}
        </button>

        <span className={`${currentTheme.text} font-mono`}>
          {currentPage} / {totalPages}
        </span>

        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className={`${currentTheme.button} px-3 py-1 rounded ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {language === "en" ? "Next" : "Sonraki"}
        </button>
      </div>
    );
  };

  const renderAnswerHistory = () => {
    const historyToShow = quizCompleted
      ? answerHistory.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      : [answerHistory[answerHistory.length - 1]];

    return (
      <div
        className={`mt-4 p-4 rounded-lg ${
          currentTheme.historyBackground
        } bg-opacity-50 backdrop-blur-sm ${
          quizCompleted ? "max-w-2xl mx-auto" : ""
        }`}
      >
        <h3
          className={`text-lg font-bold mb-2 select-none ${currentTheme.historyText}`}
        >
          {language === "en" ? "Answer History" : "Cevap Geçmişi"}
        </h3>
        <div className="space-y-1">
          {historyToShow.map((answer, index) => (
            <div
              key={index}
              className={`flex items-center space-x-2 font-mono text-sm ${currentTheme.historyText}`}
            >
              <span>{answer.questionNumber}-</span>
              <span
                className={
                  answer.isSkipped
                    ? currentTheme.skipped
                    : answer.isCorrect
                    ? currentTheme.correct
                    : currentTheme.incorrect
                }
              >
                {answer.isSkipped
                  ? language === "en"
                    ? "Skipped"
                    : "Boş Bırakıldı"
                  : answer.isCorrect
                  ? language === "en"
                    ? "Correct"
                    : "Doğru"
                  : language === "en"
                  ? "Incorrect"
                  : "Yanlış"}
              </span>
              <span>
                (Verilen cevap: {answer.isSkipped ? "Boş" : answer.userAnswer}{" "}
                Doğru cevap: {answer.answer})
              </span>
            </div>
          ))}
        </div>
        {quizCompleted &&
          answerHistory.length > itemsPerPage &&
          renderPaginationControls()}
      </div>
    );
  };

  const startQuiz = () => {
    if (!wordGroup || !wordGroup.words || wordGroup.words.length < 4) {
      setError(
        language === "en"
          ? "This quiz doesn't have enough words to start."
          : "Bu quiz başlamak için yeterli kelimeye sahip değil."
      );
      return;
    }

    // Prepare quiz words with the new format
    let quizWords = wordGroup.words.map(word => {
      // Support both old and new formats
      return {
        id: word.id || Math.floor(Math.random() * 1000000),
        question: word.question || word.turkish || "",
        answer: word.answer || word.english || "",
        isCorrect: null,
        userAnswer: "",
        revealed: false,
      };
    });

    // Shuffle the words if needed
    if (settings.randomizeOrder) {
      quizWords = shuffleArray(quizWords);
    }

    // Limit words if count is set
    if (settings.wordCount && settings.wordCount < quizWords.length) {
      quizWords = quizWords.slice(0, settings.wordCount);
    }

    setQuizWords(quizWords);
    setCurrentWordIndex(0);
    setQuizStarted(true);
    setQuizFinished(false);
    setShowResults(false);
    setCorrectCount(0);
    setWrongCount(0);
    setTotalScore(0);
    setTimeElapsed(0);
    setStartTime(Date.now());
    
    if (settings.timerEnabled && settings.timerDuration > 0) {
      setTimerValue(settings.timerDuration);
      setIsTimerRunning(true);
    }
  };

  // Helper function to render a question
  const renderQuestionText = (word) => {
    // Support both new and old formats
    const questionText = settings.questionLanguage === "english" 
      ? (word.question || word.turkish || "") 
      : (word.answer || word.english || "");
      
    return questionText;
  };

  // Helper function to render an answer
  const renderAnswerText = (word) => {
    // Support both new and old formats  
    const answerText = settings.questionLanguage === "english" 
      ? (word.answer || word.english || "") 
      : (word.question || word.turkish || "");
      
    return answerText;
  };

  // Add these helper functions to your component
  const getQuestionText = (word) => {
    return word.question || word.turkish || "";
  };

  const getAnswerText = (word) => {
    return word.answer || word.english || "";
  };

  // Add this function to calculate text similarity for partial matching
  const calculateSimilarity = (str1, str2) => {
    if (str1 === str2) return 1.0;
    if (str1.length === 0 || str2.length === 0) return 0.0;
    
    // Simple implementation using Levenshtein distance
    const matrix = Array(str1.length + 1).fill().map(() => Array(str2.length + 1).fill(0));
    
    for (let i = 0; i <= str1.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= str2.length; j++) matrix[0][j] = j;
    
    for (let i = 1; i <= str1.length; i++) {
      for (let j = 1; j <= str2.length; j++) {
        const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }
    
    const maxLength = Math.max(str1.length, str2.length);
    return 1 - (matrix[str1.length][str2.length] / maxLength);
  };

  // Add these audio elements for sound effects
  const correctSound = {
    play: () => {
      const audio = new Audio("/sounds/correct.mp3");
      audio.volume = 0.5;
      audio.play().catch(e => console.log("Audio play error:", e));
    }
  };
  
  const wrongSound = {
    play: () => {
      const audio = new Audio("/sounds/wrong.mp3");
      audio.volume = 0.5;
      audio.play().catch(e => console.log("Audio play error:", e));
    }
  };
  
  // Add this function to finish the quiz
  const finishQuiz = () => {
    setQuizFinished(true);
    setShowResults(true);
    setIsTimerRunning(false);
    setTimeElapsed((Date.now() - startTime) / 1000);
  };

  // Update the handleSubmitAnswer function 
  const handleSubmitAnswer = () => {
    const currentWord = quizWords[currentWordIndex];
    if (!currentWord) return;

    let isCorrect = false;
    const userAnswerTrimmed = userAnswer.trim();
    
    // Get the expected answer text, supporting both formats
    const correctAnswer = settings.questionLanguage === "english" 
      ? getAnswerText(currentWord) 
      : getQuestionText(currentWord);
    
    if (settings.checkType === "exact") {
      isCorrect = userAnswerTrimmed.toLowerCase() === correctAnswer.toLowerCase();
    } else {
      // Partial match checking
      const similarity = calculateSimilarity(userAnswerTrimmed.toLowerCase(), correctAnswer.toLowerCase());
      isCorrect = similarity >= settings.partialMatchThreshold;
    }

    // Update the current word with results
    const updatedQuizWords = [...quizWords];
    updatedQuizWords[currentWordIndex] = {
      ...currentWord,
      isCorrect,
      userAnswer: userAnswerTrimmed,
    };

    setQuizWords(updatedQuizWords);
    setUserAnswer("");
    
    // Update scores
    if (isCorrect) {
      setCorrectCount(prev => prev + 1);
      const pointsPerWord = 100 / quizWords.length;
      setTotalScore(prev => prev + pointsPerWord);
      
      if (settings.soundEffects) {
        correctSound.play();
      }
    } else {
      setWrongCount(prev => prev + 1);
      
      if (settings.soundEffects) {
        wrongSound.play();
      }
    }

    // Move to next word or end quiz
    if (currentWordIndex < quizWords.length - 1) {
      setCurrentWordIndex(prev => prev + 1);
    } else {
      finishQuiz();
    }
  };

  if (!wordGroup || shuffledWords.length === 0) {
    return (
      <p className={currentTheme.text}>
        {language === "en"
          ? "Word group not found."
          : "Kelime grubu bulunamadı."}
      </p>
    );
  }

  if (quizCompleted) {
    const totalQuestions = shuffledWords.length;
    const correctPercentage = (correctAnswers / totalQuestions) * 100;
    const incorrectPercentage = (incorrectAnswers / totalQuestions) * 100;
    const skippedPercentage = (skippedAnswers / totalQuestions) * 100;
    
    // Calculate score and performance metrics
    const score = Math.round((correctAnswers / totalQuestions) * 100);
    const performanceLevel = 
      score >= 90 ? (language === "en" ? "Excellent" : "Mükemmel") :
      score >= 75 ? (language === "en" ? "Very Good" : "Çok İyi") :
      score >= 60 ? (language === "en" ? "Good" : "İyi") :
      score >= 40 ? (language === "en" ? "Average" : "Orta") :
      (language === "en" ? "Needs Improvement" : "Geliştirilmeli");
    
    // Calculate time metrics if available
    const totalTimeSpent = answerHistory.reduce((total, answer) => total + (answer.timeSpent || 0), 0);
    const avgTimePerQuestion = totalTimeSpent > 0 ? (totalTimeSpent / totalQuestions).toFixed(1) : null;
    
    return (
      <div
        className={`p-6 ${currentTheme.background} ${currentTheme.text} overflow-y-auto flex-1 h-full`}
      >
        <div className="max-w-4xl mx-auto pb-10">
          <h1 className="text-2xl font-bold text-center mt-6 mb-4 select-none">
            {language === "en" ? "Quiz Completed" : "Quiz Tamamlandı"}
          </h1>
          
          <div className={`${isDarkTheme ? 'bg-white bg-opacity-10' : 'bg-gray-700 bg-opacity-15 shadow-md'} rounded-xl p-4 mb-8 text-center`}>
            <h2 className="text-xl font-bold mb-2">
              {language === "en" ? "Your Score" : "Puanınız"}: {score}/100
            </h2>
            <p className={`text-lg font-medium ${
              score >= 75 ? (isDarkTheme ? "text-green-500" : "text-green-700") : 
              score >= 40 ? (isDarkTheme ? "text-yellow-500" : "text-yellow-700") : 
              (isDarkTheme ? "text-red-500" : "text-red-700")
            }`}>
              {performanceLevel}
            </p>
          </div>

          <div className="flex flex-wrap justify-center items-center gap-6 select-none mb-8">
            <div className="w-28 sm:w-40">
              <CircularProgressbar
                value={correctPercentage}
                text={`${correctPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#22c55e" : "#15803d", // green-500 or green-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p
                className={`text-center mt-2 select-none font-medium text-sm ${isDarkTheme ? "text-green-500" : "text-green-700"}`}
              >
                {language === "en"
                  ? `Correct (${correctAnswers})`
                  : `Doğru (${correctAnswers})`}
              </p>
            </div>
            <div className="w-28 sm:w-40">
              <CircularProgressbar
                value={incorrectPercentage}
                text={`${incorrectPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#ef4444" : "#b91c1c", // red-500 or red-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p
                className={`text-center mt-2 select-none font-medium text-sm ${isDarkTheme ? "text-red-500" : "text-red-700"}`}
              >
                {language === "en"
                  ? `Incorrect (${incorrectAnswers})`
                  : `Yanlış (${incorrectAnswers})`}
              </p>
            </div>
            <div className="w-28 sm:w-40">
              <CircularProgressbar
                value={skippedPercentage}
                text={`${skippedPercentage.toFixed(0)}%`}
                styles={buildStyles({
                  textColor: currentTheme.circularProgress.textColor,
                  pathColor: isDarkTheme ? "#6b7280" : "#374151", // gray-500 or gray-700
                  trailColor: currentTheme.circularProgress.trailColor,
                })}
              />
              <p
                className={`text-center mt-2 select-none font-medium text-sm ${isDarkTheme ? "text-gray-400" : "text-gray-600"}`}
              >
                {language === "en"
                  ? `Skipped (${skippedAnswers})`
                  : `Boş (${skippedAnswers})`}
              </p>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div className={`${isDarkTheme ? 'bg-white bg-opacity-5' : 'bg-gray-300 bg-opacity-50 shadow-md'} rounded-lg p-4`}>
              <h3 className="font-bold mb-3 text-center">
                {language === "en" ? "Quiz Statistics" : "Quiz İstatistikleri"}
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>{language === "en" ? "Total Questions" : "Toplam Soru"}</span>
                  <span className="font-medium">{totalQuestions}</span>
                </div>
                <div className="flex justify-between">
                  <span>{language === "en" ? "Accuracy Rate" : "Doğruluk Oranı"}</span>
                  <span className="font-medium">{correctPercentage.toFixed(1)}%</span>
                </div>
                {avgTimePerQuestion && (
                  <div className="flex justify-between">
                    <span>{language === "en" ? "Avg. Time per Question" : "Soru Başına Ort. Süre"}</span>
                    <span className="font-medium">{avgTimePerQuestion} {language === "en" ? "sec" : "sn"}</span>
                  </div>
                )}
                <div className="flex justify-between">
                  <span>{language === "en" ? "Completion Status" : "Tamamlama Durumu"}</span>
                  <span className={`font-medium ${isDarkTheme ? "text-green-500" : "text-green-700"}`}>
                    {language === "en" ? "Completed" : "Tamamlandı"}
                  </span>
                </div>
              </div>
            </div>
            
            <div className={`${isDarkTheme ? 'bg-white bg-opacity-5' : 'bg-gray-300 bg-opacity-50 shadow-md'} rounded-lg p-4`}>
              <h3 className="font-bold mb-3 text-center">
                {language === "en" ? "Performance Analysis" : "Performans Analizi"}
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Correct Answers" : "Doğru Cevaplar"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-green-500' : 'bg-green-700'} h-2.5 rounded-full`} style={{ width: `${correctPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-green-500' : 'text-green-700'} font-medium`}>{correctAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Incorrect Answers" : "Yanlış Cevaplar"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-red-500' : 'bg-red-700'} h-2.5 rounded-full`} style={{ width: `${incorrectPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-red-500' : 'text-red-700'} font-medium`}>{incorrectAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{language === "en" ? "Skipped Questions" : "Boş Bırakılan"}</span>
                  <div className="flex items-center">
                    <div className={`w-24 ${isDarkTheme ? 'bg-gray-200' : 'bg-gray-400'} rounded-full h-2.5 mr-2`}>
                      <div className={`${isDarkTheme ? 'bg-gray-500' : 'bg-gray-700'} h-2.5 rounded-full`} style={{ width: `${skippedPercentage}%` }}></div>
                    </div>
                    <span className={`${isDarkTheme ? 'text-gray-400' : 'text-gray-600'} font-medium`}>{skippedAnswers}</span>
                  </div>
                </div>
                <div className="flex justify-between pt-1">
                  <span>{language === "en" ? "Performance Level" : "Performans Seviyesi"}</span>
                  <span className={`font-medium ${
                    score >= 75 ? (isDarkTheme ? "text-green-500" : "text-green-700") : 
                    score >= 40 ? (isDarkTheme ? "text-yellow-500" : "text-yellow-700") : 
                    (isDarkTheme ? "text-red-500" : "text-red-700")
                  }`}>{performanceLevel}</span>
                </div>
              </div>
            </div>
          </div>

          <p className="text-center select-none font-medium text-sm mb-6">
            {language === "en"
              ? `You've completed the quiz with ${correctAnswers} correct answers out of ${totalQuestions} questions.`
              : `${totalQuestions} sorudan ${correctAnswers} tanesini doğru cevapladınız.`}
          </p>

          {renderAnswerHistory()}
        </div>
      </div>
    );
  }

  return (
    <div className={`${currentTheme.background} ${currentTheme.text} overflow-y-auto flex-1 h-full`}>
      <div className="container mx-auto p-6 pb-10">
        <div className="w-full max-w-md mx-auto mb-8 select-none">
          <div className="w-48 sm:w-64 mx-auto">
            <CircularProgressbar
              value={(timeLeft / 10) * 100}
              maxValue={100}
              text={`${timeLeft}s`}
              styles={buildStyles({
                textColor: currentTheme.circularProgress.textColor,
                pathColor: getColorForTimeLeft(timeLeft),
                trailColor: currentTheme.circularProgress.trailColor,
              })}
            />
          </div>
          <div
            className={`w-full h-4 ${currentTheme.progressBar} rounded-full mt-4`}
          >
            <div
              className={`h-full ${currentTheme.progressFill} rounded-full`}
              style={{
                width: `${
                  ((currentQuestionIndex + 1) / shuffledWords.length) * 100
                }%`,
              }}
            ></div>
          </div>
          <h1 className="flex justify-center items-center text-2xl font-bold mt-4 font-mono select-none">
            {language === "en"
              ? `Question ${currentQuestionIndex + 1}/${shuffledWords.length}`
              : `Soru ${currentQuestionIndex + 1}/${shuffledWords.length}`}
          </h1>
        </div>
        <h2 className="text-2xl font-bold mt-4 mb-4 font-mono select-none justify-center flex underline">
          {getQuestionText(shuffledWords[currentQuestionIndex]) || ""}
        </h2>
        <div className="flex flex-col space-y-4">
          {answerOptions.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswerSelect(option)}
              className={`${
                currentTheme.button
              } text-xl font-bold select-none font-mono py-2 px-4 rounded ${
                selectedAnswer === option && "bg-blue-500 text-white"
              }`}
              disabled={selectedAnswer !== ""}
            >
              {option}
            </button>
          ))}
          <button
            onClick={handleSkipQuestion}
            className={`${currentTheme.skipButton} text-xl font-bold select-none font-mono py-2 px-4 rounded`}
            disabled={selectedAnswer !== ""}
          >
            {language === "en" ? "Skip Question" : "Boş Bırak"}
          </button>
        </div>
        {answerHistory.length > 0 && renderAnswerHistory()}
      </div>
    </div>
  );
};

export default Quiz;
