import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus, FaQuestion, FaCheck, FaTrash, FaSave, FaFont, FaList, FaFolderOpen, FaSearch } from "react-icons/fa";

const WordsGroup = ({ language, isDarkTheme }) => {
  const { collectionId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [wordQuestion, setWordQuestion] = useState("");
  const [wordAnswer, setWordAnswer] = useState("");
  const [wordsList, setWordsList] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [searchTerm, setSearchTerm] = useState("");

  const questionInputRef = useRef(null);
  const answerInputRef = useRef(null);

  const themes = {
    light: {
      background: "bg-gray-200",
      text: "text-gray-900",
      container: "bg-white",
      heading: "text-gray-900",
      input: {
        background: "bg-white",
        text: "text-gray-900",
        border: "border-gray-300",
        placeholder: "placeholder-gray-500",
        focus: "focus:border-blue-500 focus:ring-blue-500"
      },
      select: {
        background: "bg-white",
        text: "text-gray-900",
        border: "border-gray-300"
      },
      button: {
        primary: "bg-blue-500 hover:bg-blue-600 text-white",
        success: "bg-green-600 hover:bg-green-700 text-white",
        danger: "bg-red-500 hover:bg-red-600 text-white"
      },
      wordItem: {
        background: "bg-white",
        text: "text-gray-900",
        border: "border-gray-200"
      },
      pagination: {
        button: {
          normal: "bg-white text-gray-900 border border-gray-300 hover:bg-gray-100",
          active: "bg-blue-500 text-white border border-blue-600",
        }
      }
    },
    dark: {
      background: "bg-gray-800",
      text: "text-white",
      container: "bg-gray-900",
      heading: "text-white",
      input: {
        background: "bg-gray-900",
        text: "text-gray-100",
        border: "border-gray-600",
        placeholder: "placeholder-gray-400",
        focus: "focus:border-blue-500 focus:ring-blue-500"
      },
      select: {
        background: "bg-gray-900",
        text: "text-gray-100",
        border: "border-gray-600"
      },
      button: {
        primary: "bg-blue-500 hover:bg-blue-600 text-white",
        success: "bg-green-600 hover:bg-green-700 text-white",
        danger: "bg-red-500 hover:bg-red-600 text-white"
      },
      wordItem: {
        background: "bg-gray-900",
        text: "text-gray-200",
        border: "border-gray-700"
      },
      pagination: {
        button: {
          normal: "bg-gray-700 text-white border border-gray-600 hover:bg-gray-600",
          active: "bg-yellow-500 text-gray-900 border border-yellow-600",
        }
      }
    }
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  useEffect(() => {
    const savedCollections =
      JSON.parse(localStorage.getItem("collections")) || [];
    setCollections(savedCollections);

    if (collectionId) {
      setSelectedCollectionId(collectionId);
    }
  }, [collectionId]);

  const handleAddWord = () => {
    if (wordQuestion.trim() === "" || wordAnswer.trim() === "") {
      toast.error(
        language === "en"
          ? "Please enter both question and answer."
          : "Lütfen Soru ve Cevap girin."
      );
      return;
    }

    const trimmedQuestion = wordQuestion.trim();
    const trimmedAnswer = wordAnswer.trim();

    // Aynı soru-cevap çiftinin zaten var olup olmadığını kontrol et
    const duplicateWord = wordsList.find(
      word => 
        word.question.toLowerCase() === trimmedQuestion.toLowerCase() && 
        word.answer.toLowerCase() === trimmedAnswer.toLowerCase()
    );

    if (duplicateWord) {
      // Eğer aynı soru-cevap çifti zaten mevcutsa, hata mesajı göster
      toast.error(
        language === "en"
          ? "This question-answer pair already exists!"
          : "Bu soru-cevap çifti zaten mevcut!"
      );
      return;
    }

    const newWord = { 
      id: Date.now(), 
      question: trimmedQuestion, 
      answer: trimmedAnswer 
    };

    const updatedWordsList = [...wordsList, newWord];
    setWordsList(updatedWordsList);

    // Başarı bildirimi ekleyelim
    toast.success(
      language === "en"
        ? "Word added successfully!"
        : "Soru başarıyla eklendi!"
    );

    // Yeni eklenen kelimenin hangi sayfada olacağını hesapla
    const newPageNumber = Math.ceil(updatedWordsList.length / itemsPerPage);
    setCurrentPage(newPageNumber);

    setWordQuestion("");
    setWordAnswer("");

    // Focus on question input after adding word
    questionInputRef.current.focus();
  };

  const handleKeyPress = (e) => {
    // Check if both Enter key is pressed and both inputs have values
    if (e.key === 'Enter' && 
        wordQuestion.trim() !== "" && 
        wordAnswer.trim() !== "") {
      handleAddWord();
    }
  };

  const handleDeleteWord = (id) => {
    const updatedWordsList = wordsList.filter(word => word.id !== id);
    setWordsList(updatedWordsList);

    // Silme işleminden sonra maksimum sayfa sayısını hesapla
    const maxPage = Math.ceil(updatedWordsList.length / itemsPerPage);
    
    // Eğer mevcut sayfa numarası maksimum sayfa sayısından büyükse, son sayfaya git
    if (currentPage > maxPage) {
      setCurrentPage(maxPage || 1); // Eğer maxPage 0 ise 1'e ayarla
    }

    toast.info(
      language === "en"
        ? "Word removed from the list."
        : "Soru listeden çıkarıldı."
    );
  };

  const handleSave = () => {
    if (!groupName.trim()) {
      toast.error(
        language === "en"
          ? "Please enter a word group name."
          : "Lütfen bir quiz adı girin."
      );
      return;
    }

    if (wordsList.length < 4) {
      toast.error(
        language === "en"
          ? "You must add at least 4 words."
          : "En az 4 soru eklemelisiniz."
      );
      return;
    }

    const updatedCollections = [...collections];
    const selectedCollectionIndex = updatedCollections.findIndex(
      (col) => col.id.toString() === selectedCollectionId
    );

    if (selectedCollectionIndex === -1) {
      toast.error(
        language === "en"
          ? "Selected collection not found."
          : "Seçili koleksiyon bulunamadı."
      );
      return;
    }

    const selectedCollection = updatedCollections[selectedCollectionIndex];

    if (!selectedCollection.wordGroups) {
      selectedCollection.wordGroups = [];
    }

    const existingWordGroup = selectedCollection.wordGroups.find(
      (group) => group.name === groupName
    );

    if (existingWordGroup) {
      toast.error(
        language === "en"
          ? "A word group with this name already exists in the collection."
          : "Bu koleksiyon da zaten böyle bir quiz var."
      );
      return;
    }

    const newWordGroup = {
      id: Date.now(),
      name: groupName.trim(),
      words: wordsList,
      createdAt: new Date().toISOString()
    };

    selectedCollection.wordGroups.push(newWordGroup);
    localStorage.setItem("collections", JSON.stringify(updatedCollections));

    toast.success(
      language === "en"
        ? "Word group saved successfully!"
        : "Quiz başarıyla kaydedildi!"
    );

    // Reset form
    setGroupName("");
    setWordsList([]);
    setCurrentPage(1);
  };

  const renderPaginationControls = () => {
    // Filtrelenmiş kelime sayısına göre sayfa sayısını hesapla
    const totalPages = Math.ceil(filteredWords.length / itemsPerPage);
    
    if (totalPages <= 1) return null;
    
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`w-8 h-8 rounded-full mx-1 transition-colors duration-200 ${
            currentPage === i
              ? currentTheme.pagination.button.active 
              : currentTheme.pagination.button.normal
          }`}
        >
          {i}
        </button>
      );
    }
    
    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`w-8 h-8 rounded-full mx-1 flex items-center justify-center ${
            currentPage === 1
              ? 'opacity-50 cursor-not-allowed'
              : ''
          } ${currentTheme.pagination.button.normal}`}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className={`w-8 h-8 rounded-full mx-1 flex items-center justify-center ${
            currentPage === totalPages
              ? 'opacity-50 cursor-not-allowed'
              : ''
          } ${currentTheme.pagination.button.normal}`}
        >
          &gt;
        </button>
      </div>
    );
  };

  // İndex eklemeyi dahil ederek paginasyonu işle
  const filteredWords = wordsList.filter(word => 
    word.question.toLowerCase().includes(searchTerm.toLowerCase()) || 
    word.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const paginatedWords = filteredWords.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ).map((word, index) => ({
    ...word,
    displayIndex: (currentPage - 1) * itemsPerPage + index + 1,
  }));

  useEffect(() => {
    // Reset to first page when search term changes
    setCurrentPage(1);
  }, [searchTerm]);
  
  // Update totalPages calculation based on filtered words
  const totalPages = Math.max(1, Math.ceil(filteredWords.length / itemsPerPage));

  return (
    <div className={`fixed inset-0 ${currentTheme.background} flex flex-col pt-16`}>
      <div className="flex flex-col flex-grow w-full h-full overflow-auto pb-16">
        <div className="container mx-auto p-4">
          <ToastContainer 
            position="top-center" 
            autoClose={3000}
            style={{ marginTop: '60px' }}
          />

          <h1 className={`text-2xl font-bold text-center mb-4 ${currentTheme.heading} flex items-center justify-center gap-2`}>
            <FaPlus />
            {language === "en" ? "Add New Quiz" : "Yeni Quiz Ekle"}
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 flex-grow">
            {/* Form Section - Left Side */}
            <div className={`p-6 rounded-xl shadow-lg ${currentTheme.container}`}>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="group-name"
                    className={`block mb-2 font-medium ${currentTheme.text} flex items-center gap-2`}
                  >
                    <FaFont />
                    {language === "en" ? "Quiz Name" : "Quiz Adı"}
                  </label>
                  <input
                    type="text"
                    id="group-name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.background} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                    placeholder={
                      language === "en" ? "Enter quiz name" : "Quiz adını girin"
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="collection"
                    className={`block mb-2 font-medium ${currentTheme.text} flex items-center gap-2`}
                  >
                    <FaFolderOpen />
                    {language === "en" ? "Collection" : "Koleksiyon"}
                  </label>
                  <select
                    id="collection"
                    value={selectedCollectionId}
                    onChange={(e) => setSelectedCollectionId(e.target.value)}
                    className={`w-full px-4 py-3 rounded-lg ${currentTheme.select.background} ${currentTheme.select.text} border ${currentTheme.select.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                  >
                    <option value="">
                      {language === "en"
                        ? "Select a collection"
                        : "Bir koleksiyon seçin"}
                    </option>
                    {collections.map((collection) => (
                      <option key={collection.id} value={collection.id.toString()}>
                        {collection.name}
                      </option>
                    ))}
                  </select>
                </div>

                <h2 className={`text-xl font-semibold mt-6 mb-4 ${currentTheme.heading} flex items-center gap-2`}>
                  <FaPlus />
                  {language === "en" ? "Add New Word" : "Yeni Soru Ekle"}
                </h2>

                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="question"
                      className={`block mb-2 font-medium ${currentTheme.text} flex items-center gap-2`}
                    >
                      <FaQuestion />
                      {language === "en" ? "Word" : "Soru"}
                    </label>
                    <input
                      type="text"
                      id="question"
                      ref={questionInputRef}
                      value={wordQuestion}
                      onChange={(e) => setWordQuestion(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.background} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                      placeholder={
                        language === "en" ? "Enter question" : "Soru girin"
                      }
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="answer"
                      className={`block mb-2 font-medium ${currentTheme.text} flex items-center gap-2`}
                    >
                      <FaCheck />
                      {language === "en" ? "Answer" : "Cevap"}
                    </label>
                    <input
                      type="text"
                      id="answer"
                      ref={answerInputRef}
                      value={wordAnswer}
                      onChange={(e) => setWordAnswer(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.background} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                      placeholder={
                        language === "en" ? "Enter answer" : "Cevap girin"
                      }
                    />
                  </div>

                  <div className="flex justify-end mt-4">
                    <button
                      onClick={handleAddWord}
                      className={`px-6 py-3 rounded-lg ${currentTheme.button.primary} shadow-md transition-colors duration-200 font-medium flex items-center gap-2`}
                    >
                      <FaPlus />
                      {language === "en" ? "Add Word" : "Ekle"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Word List Section - Right Side */}
            <div className={`p-6 rounded-xl shadow-lg ${currentTheme.container} flex flex-col`}>
              <h2 className={`text-xl font-semibold mb-4 ${currentTheme.heading} flex items-center gap-2`}>
                <FaList />
                {language === "en" ? "Word List" : "Soru Listesi"} ({filteredWords.length})
              </h2>
              
              {/* Search Bar */}
              <div className="mb-4">
                <div className={`flex items-center border ${currentTheme.input.border} rounded-lg overflow-hidden ${currentTheme.input.background}`}>
                  <div className="px-3">
                    <FaSearch className={currentTheme.input.text} />
                  </div>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`w-full px-2 py-3 ${currentTheme.input.background} ${currentTheme.input.text} focus:outline-none transition-colors duration-200`}
                    placeholder={language === "en" ? "Search in words and answers..." : "Sorular ve cevaplarda ara..."}
                  />
                </div>
              </div>
              
              <div className="space-y-3 flex-grow overflow-y-auto pr-2 mb-4">
                {filteredWords.length === 0 ? (
                  <p className={`italic ${currentTheme.text} text-center py-6`}>
                    {searchTerm ? 
                      (language === "en" ? "No matching words found." : "Eşleşen soru bulunamadı.") :
                      (language === "en" ? "No words added yet." : "Henüz soru eklenmedi.")
                    }
                  </p>
                ) : (
                  paginatedWords.map((word) => (
                    <div
                      key={word.id}
                      className={`flex justify-between items-center p-4 rounded-lg shadow-sm ${currentTheme.wordItem.background} border ${currentTheme.wordItem.border} transition-colors duration-200`}
                    >
                      <span className={`${currentTheme.wordItem.text} font-medium`}>
                        {word.displayIndex}. {word.question} - {word.answer}
                      </span>
                      <button
                        onClick={() => handleDeleteWord(word.id)}
                        className={`px-4 py-2 rounded-lg ${currentTheme.button.danger} transition-colors duration-200 ml-2 shrink-0 flex items-center gap-2`}
                      >
                        <FaTrash />
                        {language === "en" ? "Delete" : "Sil"}
                      </button>
                    </div>
                  ))
                )}
              </div>
              
              {renderPaginationControls()}
            </div>
          </div>

          <div className="flex justify-center mt-6 mb-6">
            <button
              onClick={handleSave}
              disabled={!selectedCollectionId || wordsList.length < 4}
              className={`px-8 py-3 rounded-lg font-medium text-lg transition-colors duration-200 shadow-md flex items-center gap-2 ${
                !selectedCollectionId || wordsList.length < 4
                  ? "bg-gray-400 cursor-not-allowed text-white"
                  : `${currentTheme.button.success} text-white`
              }`}
            >
              <FaSave />
              {language === "en" ? "Save Quiz" : "Kaydet"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordsGroup;