import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaQuestion, FaCheck, FaTrash, FaSave, FaFont, FaPlus, FaList, FaSearch } from "react-icons/fa";

const EditWordGroup = ({ language, isDarkTheme }) => {
  const { groupId, collectionId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [wordQuestion, setWordQuestion] = useState("");
  const [wordAnswer, setWordAnswer] = useState("");
  const [wordsList, setWordsList] = useState([]);
  const [collections, setCollections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const navigate = useNavigate();
  const questionInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  const themes = {
    light: {
      background: "bg-gray-200",
      container: "bg-white",
      text: "text-gray-800",
      heading: "text-gray-900",
      input: {
        bg: "bg-white",
        text: "text-gray-800",
        border: "border-gray-300",
        placeholder: "placeholder-gray-500",
        focus: "focus:border-blue-500 focus:ring-blue-500"
      },
      addButton: {
        bg: "bg-blue-500",
        hover: "hover:bg-blue-600"
      },
      deleteButton: {
        bg: "bg-red-600",
        hover: "hover:bg-red-700"
      },
      saveButton: {
        bg: "bg-green-600",
        hover: "hover:bg-green-700"
      },
      wordItem: {
        text: "text-gray-700",
        bg: "bg-white",
        border: "border-gray-200"
      },
      pagination: {
        button: {
          normal: "bg-white text-gray-900 border border-gray-300 hover:bg-gray-100",
          active: "bg-blue-500 text-white border border-blue-600",
        }
      }
    },
    dark: {
      background: "bg-gray-800",
      container: "bg-gray-900",
      text: "text-gray-100",
      heading: "text-white",
      input: {
        bg: "bg-gray-900",
        text: "text-gray-100",
        border: "border-gray-600",
        placeholder: "placeholder-gray-400",
        focus: "focus:border-blue-500 focus:ring-blue-500"
      },
      addButton: {
        bg: "bg-blue-600",
        hover: "hover:bg-blue-700"
      },
      deleteButton: {
        bg: "bg-red-700",
        hover: "hover:bg-red-800"
      },
      saveButton: {
        bg: "bg-green-700",
        hover: "hover:bg-green-800"
      },
      wordItem: {
        text: "text-gray-200",
        bg: "bg-gray-900",
        border: "border-gray-700"
      },
      pagination: {
        button: {
          normal: "bg-gray-700 text-white border border-gray-600 hover:bg-gray-600",
          active: "bg-yellow-500 text-gray-900 border border-yellow-600",
        }
      }
    }
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  useEffect(() => {
    try {
      console.log("Loading data for group ID:", groupId);
      const savedCollections = JSON.parse(localStorage.getItem("collections")) || [];
      setCollections(savedCollections);
  
      const group = findWordGroup(savedCollections, groupId);
      console.log("Found group:", group);
      
      if (group) {
        setGroupName(group.name);
        
        // Kelimelerin doğru bir şekilde yüklendiğinden emin olalım
        const wordList = Array.isArray(group.words) ? group.words : [];
        console.log("Loading words:", wordList);
        
        // Her kelimeye benzersiz ID ataması yapalım
        const wordsWithValidIds = wordList.map(word => ({
          ...word,
          id: word.id || Date.now() + Math.floor(Math.random() * 1000)
        }));
        
        setWordsList(wordsWithValidIds);
      } else {
        console.error("Group not found with ID:", groupId);
        toast.error(
          language === "en"
            ? "Quiz group not found."
            : "Quiz grubu bulunamadı."
        );
      }
    } catch (error) {
      console.error("Error loading collections from localStorage:", error);
      setCollections([]);
      toast.error(
        language === "en"
          ? "Error loading data: " + error.message
          : "Veri yüklenirken hata: " + error.message
      );
    }
  }, [groupId]);

  const findWordGroup = (collections, groupId) => {
    const targetId = groupId.toString();
    console.log("Looking for group ID:", targetId);
    
    for (let collection of collections) {
      console.log("Checking collection:", collection.name || collection.id);
      
      if (Array.isArray(collection.wordGroups)) {
        for (let group of collection.wordGroups) {
          const groupIdStr = (group.id || "").toString();
          console.log("Comparing group ID:", groupIdStr, "with target:", targetId);
          
          if (groupIdStr === targetId) {
            console.log("Match found!");
            return group;
          }
        }
      }
    }
    console.log("No matching group found");
    return null;
  };

  const handleAddWord = () => {
    // Boş soru veya cevap kontrolü
    if (wordQuestion.trim() === "" || wordAnswer.trim() === "") {
      toast.error(
        language === "en"
          ? "Please enter both question and answer."
          : "Lütfen soru ve cevap girin."
      );
      return;
    }

    const trimmedQuestion = wordQuestion.trim();
    const trimmedAnswer = wordAnswer.trim();

    // Aynı soru-cevap çiftinin zaten var olup olmadığını kontrol et
    const duplicateWord = wordsList.find(
      word => 
        word.question.toLowerCase() === trimmedQuestion.toLowerCase() && 
        word.answer.toLowerCase() === trimmedAnswer.toLowerCase()
    );

    if (duplicateWord) {
      // Eğer aynı soru-cevap çifti zaten mevcutsa, hata mesajı göster
      toast.error(
        language === "en"
          ? "This question-answer pair already exists!"
          : "Bu soru-cevap çifti zaten mevcut!"
      );
      return;
    }

    const newWord = {
      id: Date.now(),
      question: trimmedQuestion,
      answer: trimmedAnswer
    };

    const updatedWordsList = [...wordsList, newWord];
    setWordsList(updatedWordsList);
    setWordQuestion("");
    setWordAnswer("");

    // Başarı bildirimi ekleyelim
    toast.success(
      language === "en"
        ? "Word added successfully!"
        : "Soru başarıyla eklendi!"
    );

    // Calculate what page the new item will be on
    const newPage = Math.ceil(updatedWordsList.length / itemsPerPage);
    setCurrentPage(newPage);

    // Focus back on the first input
    questionInputRef.current.focus();
  };

  const handleUpdateWord = (id, updatedWord) => {
    const updatedList = wordsList.map(word => 
      word.id === id ? { ...word, ...updatedWord } : word
    );
    setWordsList(updatedList);
  };

  const handleDeleteWord = (id) => {
    try {
      console.log("Deleting word with ID:", id);
      console.log("Current wordsList before delete:", wordsList);
      
      // ID'yi sayıya çevir (eğer string ise)
      const wordId = typeof id === 'string' ? parseInt(id) : id;
      
      // Sadece bu ID'ye sahip kelimeyi bul
      const wordToDelete = wordsList.find(word => word.id === wordId);
      console.log("Word to delete:", wordToDelete);
      
      if (!wordToDelete) {
        console.error("Word not found with ID:", wordId);
        toast.error(
          language === "en" 
            ? "Error: Word not found" 
            : "Hata: Soru bulunamadı"
        );
        return;
      }
      
      // Silinecek kelimenin index'ini bul
      const index = wordsList.findIndex(word => word.id === wordId);
      console.log("Word index in the array:", index);
      
      // Doğrudan kopyalama yöntemiyle filtreleme
      const updatedWordsList = wordsList.filter(word => word.id !== wordId);
      console.log("Updated wordsList after delete:", updatedWordsList);
      
      // State'i tamamen yenile
      setWordsList([...updatedWordsList]);
      
      // Başarı bildirimi
      toast.success(
        language === "en"
          ? `Word "${wordToDelete.question}" deleted.`
          : `"${wordToDelete.question}" sorusu silindi.`
      );
      
      // Filtrelenmiş sonuçları kullanarak sayfa hesaplaması
      // Silme işleminden sonra mevcut sayfada kalmaya çalışalım, 
      // ancak mevcut sayfa artık boşsa bir önceki sayfaya gidelim
      const updatedFilteredWords = searchTerm 
        ? updatedWordsList.filter(word => {
            const searchTermLower = searchTerm.toLowerCase();
            return (
              (word.question && word.question.toLowerCase().includes(searchTermLower)) || 
              (word.answer && word.answer.toLowerCase().includes(searchTermLower))
            );
          })
        : updatedWordsList;
        
      const newMaxPage = Math.max(1, Math.ceil(updatedFilteredWords.length / itemsPerPage));
      
      // Eğer şu anki sayfa numarası yeni maksimum sayfa numarasından büyükse, son sayfaya ayarla
      if (currentPage > newMaxPage) {
        setCurrentPage(newMaxPage);
      }
    } catch (error) {
      console.error("Error deleting word:", error);
      toast.error(
        language === "en"
          ? "An error occurred while deleting the word."
          : "Soru silinirken bir hata oluştu."
      );
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && wordQuestion.trim() !== "" && wordAnswer.trim() !== "") {
      handleAddWord();
    }
  };

  const handleSave = () => {
    if (!groupName.trim()) {
      toast.error(
        language === "en"
          ? "Please enter a word group name."
          : "Lütfen bir quiz adı girin."
      );
      return;
    }
  
    if (wordsList.length < 4) {
      toast.error(
        language === "en"
          ? "You must add at least 4 words."
          : "En az 4 soru eklemelisiniz."
      );
      return;
    }
  
    try {
      // Önce localStorage'dan en güncel koleksiyonları alalım
      const currentCollections = JSON.parse(localStorage.getItem("collections")) || [];
      
      const updatedCollections = currentCollections.map((col) => {
        if (col.id.toString() === collectionId) {
          // Varolan kelime grubunu bulalım
          let hasGroup = false;
          const updatedWordGroups = Array.isArray(col.wordGroups)
            ? col.wordGroups.map((group) => {
                if (group.id.toString() === groupId) {
                  hasGroup = true;
                  return { 
                    ...group, 
                    name: groupName.trim(), 
                    words: [...wordsList], // Kopya oluşturarak veri güvenliğini sağlayalım
                    updatedAt: new Date().toISOString()
                  };
                }
                return group;
              })
            : [];
          
          // Eğer grup bulunamadıysa (olmaması gereken bir durum) hata gösterelim
          if (!hasGroup) {
            toast.error(
              language === "en"
                ? "Quiz group not found. Please try again."
                : "Quiz grubu bulunamadı. Lütfen tekrar deneyin."
            );
            throw new Error("Quiz group not found");
          }
          
          return {
            ...col,
            wordGroups: updatedWordGroups,
          };
        }
        return col;
      });
  
      localStorage.setItem("collections", JSON.stringify(updatedCollections));
      setCollections(updatedCollections);
      
      toast.success(
        language === "en"
          ? "Word group successfully updated."
          : "Quiz başarıyla güncellendi."
      );
      
      navigate(`/collection/${collectionId}`);
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error(
        language === "en"
          ? "An error occurred while saving: " + error.message
          : "Kaydetme sırasında bir hata oluştu: " + error.message
      );
    }
  };

  const renderPaginationControls = () => {
    // Filtrelenmiş kelime sayısına göre sayfa sayısını hesapla
    // const totalPages = Math.ceil(filteredWords.length / itemsPerPage);
    // Yukarıda hesapladığımız totalFilteredPages değerini kullanalım
    
    if (totalFilteredPages <= 1) return null;
    
    const pages = [];
    for (let i = 1; i <= totalFilteredPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`w-8 h-8 rounded-full mx-1 transition-colors duration-200 ${
            currentPage === i
              ? currentTheme.pagination.button.active
              : currentTheme.pagination.button.normal
          }`}
        >
          {i}
        </button>
      );
    }
    
    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`w-8 h-8 rounded-full mx-1 flex items-center justify-center ${
            currentPage === 1
              ? 'opacity-50 cursor-not-allowed'
              : ''
          } ${currentTheme.pagination.button.normal}`}
        >
          &lt;
        </button>
        
        {pages}
        
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalFilteredPages))}
          disabled={currentPage === totalFilteredPages}
          className={`w-8 h-8 rounded-full mx-1 flex items-center justify-center ${
            currentPage === totalFilteredPages
              ? 'opacity-50 cursor-not-allowed'
              : ''
          } ${currentTheme.pagination.button.normal}`}
        >
          &gt;
        </button>
      </div>
    );
  };

  // Filter words by search term
  const filteredWords = wordsList.filter(word => {
    if (!searchTerm) return true; // Arama terimi yoksa tüm kelimeleri göster
    
    // Arama terimi varsa, hem soru hem cevap içinde arama yap (büyük-küçük harf duyarsız)
    const searchTermLower = searchTerm.toLowerCase();
    return (
      (word.question && word.question.toLowerCase().includes(searchTermLower)) || 
      (word.answer && word.answer.toLowerCase().includes(searchTermLower))
    );
  });

  // Silme işleminden sonra filtrelenmiş sonuçların doğru gösterildiğinden emin olmak için
  // totalPages ve currentPage değerlerini güncelleyelim
  const totalFilteredPages = Math.max(1, Math.ceil(filteredWords.length / itemsPerPage));
  
  useEffect(() => {
    // Reset to first page when search term changes
    setCurrentPage(1);
  }, [searchTerm]);

  // Compute the current page of words, filtrelenmiş sonuçları kullan
  const currentWords = filteredWords.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ).map((word, idx) => ({
    ...word,
    displayIndex: (currentPage - 1) * itemsPerPage + idx + 1
  }));

  return (
    <div className={`fixed inset-0 ${currentTheme.background} flex flex-col pt-16`}>
      <div className="flex flex-col flex-grow w-full h-full overflow-auto pb-16">
        <div className="container mx-auto p-4">
          <ToastContainer 
            position="top-center" 
            autoClose={3000} 
            style={{ marginTop: '60px' }} // Navbar'ın altına yerleştir
          />
          
          <h1 className={`text-2xl font-bold mb-4 text-center ${currentTheme.heading} flex items-center justify-center gap-2`}>
            <FaEdit />
            {language === "en" ? "Edit Quiz" : "Quiz Düzenle"}
          </h1>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 flex-grow">
            {/* Form Section - Left Side */}
            <div className={`p-6 rounded-xl shadow-lg ${currentTheme.container}`}>
              <div className="mb-6">
                <label className={`block mb-2 font-medium ${currentTheme.text} flex items-center gap-2`}>
                  <FaFont />
                  {language === "en" ? "Quiz Name" : "Quiz Adı"}
                </label>
                <input
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.bg} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                  placeholder={language === "en" ? "Enter quiz name" : "Quiz adını girin"}
                />
              </div>
              
              <div className="space-y-4">
                <h2 className={`text-xl font-semibold mb-4 ${currentTheme.heading} flex items-center gap-2`}>
                  <FaPlus />
                  {language === "en" ? "Add New Word" : "Yeni Soru Ekle"}
                </h2>
                
                <div className="space-y-4">
                  <div>
                    <label className={`block mb-2 ${currentTheme.text} flex items-center gap-2`}>
                      <FaQuestion />
                      {language === "en" ? "Word" : "Soru"}
                    </label>
                    <input
                      type="text"
                      ref={questionInputRef}
                      value={wordQuestion}
                      onChange={(e) => setWordQuestion(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.bg} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                      placeholder={language === "en" ? "Enter question" : "Soru girin"}
                    />
                  </div>
                  
                  <div>
                    <label className={`block mb-2 ${currentTheme.text} flex items-center gap-2`}>
                      <FaCheck />
                      {language === "en" ? "Answer" : "Cevap"}
                    </label>
                    <input
                      type="text"
                      value={wordAnswer}
                      onChange={(e) => setWordAnswer(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={`w-full px-4 py-3 rounded-lg ${currentTheme.input.bg} ${currentTheme.input.text} border ${currentTheme.input.border} ${currentTheme.input.focus} focus:outline-none transition-colors duration-200`}
                      placeholder={language === "en" ? "Enter answer" : "Cevap girin"}
                    />
                  </div>
                  
                  <div className="flex justify-end">
                    <button
                      onClick={handleAddWord}
                      className={`px-6 py-3 rounded-lg text-white font-medium ${currentTheme.addButton.bg} ${currentTheme.addButton.hover} transition-colors duration-200 shadow-md flex items-center gap-2`}
                    >
                      <FaPlus />
                      {language === "en" ? "Add Word" : "Soru Ekle"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Word List Section - Right Side */}
            <div className={`p-6 rounded-xl shadow-lg ${currentTheme.container} flex flex-col`}>
              <h2 className={`text-xl font-semibold mb-4 ${currentTheme.heading} flex items-center gap-2`}>
                <FaList />
                {language === "en" ? "Word List" : "Soru Listesi"} ({filteredWords.length})
              </h2>
              
              {/* Search Bar */}
              <div className="mb-4">
                <div className={`flex items-center border ${currentTheme.input.border} rounded-lg overflow-hidden ${currentTheme.input.bg}`}>
                  <div className="px-3">
                    <FaSearch className={currentTheme.input.text} />
                  </div>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`w-full px-2 py-3 ${currentTheme.input.bg} ${currentTheme.input.text} focus:outline-none transition-colors duration-200`}
                    placeholder={language === "en" ? "Search in words and answers..." : "Sorular ve cevaplarda ara..."}
                  />
                </div>
              </div>
              
              <div className="space-y-3 flex-grow overflow-y-auto pr-2 mb-4">
                {currentWords.length === 0 ? (
                  <p className={`italic ${currentTheme.text} text-center py-6`}>
                    {searchTerm ? 
                      (language === "en" ? "No matching words found." : "Eşleşen soru bulunamadı.") :
                      (language === "en" ? "No words added yet." : "Henüz soru eklenmedi.")
                    }
                  </p>
                ) : (
                  currentWords.map((word) => (
                    <div
                      key={word.id}
                      className={`flex justify-between items-center p-4 rounded-lg shadow-sm ${currentTheme.wordItem.bg} border ${currentTheme.wordItem.border} transition-colors duration-200`}
                    >
                      <span className={`${currentTheme.wordItem.text} font-medium flex-grow mr-2`}>
                        {word.displayIndex}. {word.question} - {word.answer}
                      </span>
                      <button
                        onClick={() => {
                          console.log("Delete button clicked for word:", word);
                          handleDeleteWord(word.id);
                        }}
                        data-word-id={word.id}
                        className={`px-4 py-2 rounded-lg text-white ${currentTheme.deleteButton.bg} ${currentTheme.deleteButton.hover} transition-colors duration-200 ml-2 shrink-0 flex items-center gap-2`}
                      >
                        <FaTrash />
                        {language === "en" ? "Delete" : "Sil"}
                      </button>
                    </div>
                  ))
                )}
              </div>
              
              {renderPaginationControls()}
            </div>
          </div>
          
          <div className="flex justify-center mt-6 mb-6">
            <button
              onClick={handleSave}
              className={`px-8 py-3 rounded-lg text-white font-medium text-lg ${currentTheme.saveButton.bg} ${currentTheme.saveButton.hover} transition-colors duration-200 shadow-md flex items-center gap-2`}
            >
              <FaSave />
              {language === "en" ? "Save Changes" : "Değişiklikleri Kaydet"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWordGroup;