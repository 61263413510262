import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

const Collection = ({ onCollectionCreated, language, isDarkTheme }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const themes = {
    light: {
      background: "bg-gray-100",
      text: "text-gray-900",
      container: "bg-white",
      heading: "text-gray-900",
      input: {
        background: "bg-white",
        text: "text-gray-900",
        border: "border-gray-300",
        placeholder: "placeholder-gray-500",
        focus: "focus:border-blue-500"
      },
      button: {
        background: "bg-blue-600",
        hover: "hover:bg-blue-700",
        text: "text-white"
      },
      error: "text-red-600"
    },
    dark: {
      background: "bg-gray-800",
      text: "text-white",
      container: "bg-gray-900",
      heading: "text-white",
      input: {
        background: "bg-gray-900",
        text: "text-white",
        border: "border-gray-600",
        placeholder: "placeholder-gray-400",
        focus: "focus:border-blue-500"
      },
      button: {
        background: "bg-blue-600",
        hover: "hover:bg-blue-700",
        text: "text-white"
      },
      error: "text-red-500"
    }
  };

  const currentTheme = isDarkTheme ? themes.dark : themes.light;

  const handleSave = () => {
    const collections = JSON.parse(localStorage.getItem("collections")) || [];

    if (collections.some((collection) => collection.name === name)) {
      setError(
        language === "en"
          ? "A collection with this name already exists."
          : "Bu isimde bir koleksiyon zaten var."
      );
      toast.error(
        language === "en"
          ? "A collection with this name already exists."
          : "Bu isimde bir koleksiyon zaten var."
      );
      return;
    }

    const newCollection = {
      id: Date.now(),
      name,
      createdAt: new Date().toISOString(),
    };

    collections.push(newCollection);
    localStorage.setItem("collections", JSON.stringify(collections));
    setName("");
    setError("");
    if (onCollectionCreated) {
      onCollectionCreated(newCollection);
    }

    toast.success(
      language === "en"
        ? "Collection created successfully."
        : "Koleksiyon başarıyla oluşturuldu."
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div 
      className={`min-h-screen transition-colors duration-300 ${currentTheme.background} ${currentTheme.text}`}
    >
      <div className="container mx-auto p-6">
        <h1 
          className={`text-3xl font-bold mb-6 text-center cursor-pointer select-none ${currentTheme.heading}`}
        >
          {language === "en" ? "Create Collection" : "Koleksiyon Oluştur"}
        </h1>
        <div className="max-w-md mx-auto">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder={
              language === "en" ? "Collection Name" : "Koleksiyon Adı"
            }
            className={`border-2 rounded-md py-2 px-4 w-full mb-4 focus:outline-none transition-colors duration-300
              ${currentTheme.input.background}
              ${currentTheme.input.text}
              ${currentTheme.input.border}
              ${currentTheme.input.placeholder}
              ${currentTheme.input.focus}`}
          />
          {error && (
            <p className={`mb-4 text-center ${currentTheme.error}`}>
              {error}
            </p>
          )}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSave}
            className={`py-2 px-6 rounded-md w-full focus:outline-none font-bold transition-colors duration-300
              ${currentTheme.button.background}
              ${currentTheme.button.hover}
              ${currentTheme.button.text}`}
          >
            {language === "en" ? "Save" : "Kayıt Et"}
          </motion.button>
        </div>
      </div>
      <ToastContainer 
        position="bottom-right" 
        autoClose={3000}
        theme={isDarkTheme ? "dark" : "light"}
      />
    </div>
  );
};

export default Collection;